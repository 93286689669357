import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useVillaList } from './useVillaList';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AppModal from '../../components/Modal/AppModal';
import DetailVilla from './DetailVilla/DetailVilla';

const Villa = () => {
  const {
    isOpen,
    villaList,
    villaSelected,
    selectedDate,
    getStatus,
    handleClose,
    getStatusBg,
    openVillaDetail,
    handleDateChange,
  } = useVillaList();

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Villa List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Villa List</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-5 mt-3 ml-3">
            <div className="col-lg-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Pilih Tanggal"
                  value={selectedDate}
                  onChange={handleDateChange}
                  animateYearScrolling
                  autoOk
                  format="dd MMMM yyyy"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <Card className="text-center">
                <Card.Body className="text-bold">
                  <h6><b>Silahkan klik Villa untuk merubah harga.</b></h6>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row">
            {villaList.map((x, index: number) => (
              <div
                className="col-lg-2"
                key={index}
              >
                <Card className="text-center">
                  <Card.Body
                    className="text-bold"
                    onClick={() => openVillaDetail(x.id!)}
                    style={{ cursor: "pointer" }}
                  >
                    {x.name}<br />
                    Rp. {x.price?.toLocaleString("id-ID")} / malam
                  </Card.Body>
                  <Card.Footer className={getStatusBg(x.order!.orderstatus)} style={{ cursor: "default" }}>
                    <div className="col-12">
                      {getStatus(x.order!.orderstatus)}
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <AppModal
        title={"Ubah Harga"}
        isOpen={isOpen}
        closeAction={handleClose}
        hasCustomButton={true}
      >
        <DetailVilla
          {...villaSelected}
          closeAction={handleClose}
        />
      </AppModal>
    </>
  );
};

export default Villa;
