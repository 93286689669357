import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { IUser, useUserList } from '../useUserList';
import { Formik } from 'formik';
import * as yup from 'yup';

type IDetailUserProps = {
  closeAction: VoidFunction;
  isEdit?: boolean;
  username: string;
  password?: string;
  // email: string;
  mobile: string;
  role: string;
  status: string;
};

const predefinedRole = [
  {
    name: 'Operator',
    val: 'OPRT',
  },
  {
    name: 'Super Admin',
    val: 'SADM',
  },
  {
    name: 'Owner',
    val: 'OWNR',
  },
];

const formSchemaEdit = yup.object<IUser>({
  username: yup.string().required(),
  // email: yup.string().required(),
  mobile: yup.string().required(),
  role: yup.string().required(),
  status: yup.string(),
});

const formSchemaCreate = yup.object<IUser>({
  username: yup.string().required(),
  password: yup.string().required(),
  // email: yup.string().required(),
  mobile: yup.string().required(),
  role: yup.string().required(),
  status: yup.string(),
});

const DetailUser = (props: IDetailUserProps) => {
  const { createNewUser, editUser, handleClose } = useUserList();
  const {
    closeAction,
    username,
    password,
    // email,
    role,
    mobile,
    status,
    isEdit,
  } = props;
  const [userDetail, setUserDetail] = useState<IUser>({
    username,
    password,
    // email,
    role,
    mobile,
    status,
  });

  return (
    <Row className="my-2">
      <Col md="12">
        <Formik
          enableReinitialize
          initialValues={userDetail}
          validationSchema={isEdit ? formSchemaEdit : formSchemaCreate}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            console.log(values);
            !isEdit ? await createNewUser(values) : await editUser(values);
            setSubmitting(false);
            closeAction();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} controlId="usernameCtrl">
                <Form.Label column sm={2}>
                  Username
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    disabled={isEdit ? true : false}
                    autoComplete="off"
                    name="username"
                    placeholder="Username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    isInvalid={!!errors.username && !!touched.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {!isEdit ? (
                <Form.Group as={Row} controlId="passwordCtrl">
                  <Form.Label column sm={2}>
                    Password
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      autoComplete="off"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      isInvalid={!!errors.password && !!touched.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              ) : null}
              {/* <Form.Group as={Row} controlId="emailCtrl">
                <Form.Label column sm={2}>
                  Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={!!errors.email && !!touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group> */}
              <Form.Group as={Row} controlId="mobileCtrl">
                <Form.Label column sm={2}>
                  Mobile
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="mobile"
                    placeholder="Mobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile}
                    isInvalid={!!errors.mobile && !!touched.mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="roleCtrl">
                <Form.Label column sm={2}>
                  Role
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    custom
                    name="role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                    isInvalid={!!errors.role && !!touched.role}
                  >
                    {predefinedRole.map((x, index: number) => (
                      <option key={index} value={x.val}>
                        {x.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.role}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {isEdit ? (
                <Form.Group as={Row} controlId="statusCtrl">
                  <Form.Label column sm={2}>
                    Status
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      type="switch"
                      name="status"
                      id="status_switch"
                      label={values.status == 'ACTIVE' ? 'ACTIVE' : 'SUSPEN'}
                      onChange={(e: any) => {
                        handleChange(e);
                        !!e.target.checked
                          ? setFieldValue('status', 'ACTIVE')
                          : setFieldValue('status', 'SUSPEN');
                      }}
                      onBlur={handleBlur}
                      checked={values.status === 'ACTIVE' ? true : false}
                    />
                  </Col>
                </Form.Group>
              ) : null}
              <React.Fragment>
                <Button
                  variant="default"
                  className="float-right mr-2"
                  onClick={closeAction}
                >
                  Close
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  className="float-right mr-2"
                  disabled={isSubmitting && !errors}
                >
                  Simpan
                </Button>
              </React.Fragment>
            </form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default DetailUser;
