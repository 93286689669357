import { grpc } from '@improbable-eng/grpc-web';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { LoadingActionsContext } from '../../contexts/LoadingContext';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import {
  DtlAvailResDtoList,
  DtlBlmLunasResDtoList,
  DtlBookedResDtoList,
  DtlCheckinResDtoList,
  DtlCheckoutResDtoList,
  HomeDto,
} from '../../protofile/home/home_pb';
import { HomeApi } from '../../protofile/home/home_pb_service';

export interface IHomeData {
  avail: number;
  blmlunas: number;
  book: number;
  cin: number;
  cout: number;
  gross: number;
}

const initialHomeData: IHomeData = {
  avail: 0,
  blmlunas: 0,
  book: 0,
  cin: 0,
  cout: 0,
  gross: 0,
};

type WrapperDataRoom = {
  title: string;
  data: [];
};

export const useHome = () => {
  const { user } = useContext(AuthContext);
  const { invokeRequest } = useContext<IServiceActions>(ServiceActionsContext);
  const { showToast } = useContext(ToasterActionsContext);

  const { setIsLoading } = useContext(LoadingActionsContext);

  const [dataHome, setdataHome] = useState<IHomeData>(initialHomeData);
  const [dataRoomDetail, setDataRoomDetail] = useState<WrapperDataRoom>({
    title: '',
    data: [],
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);

  const getHomeData = async () => {
    const homeRequest = new HomeDto();
    try {
      invokeRequest({
        methodDescriptor: HomeApi.Home,
        request: homeRequest,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setdataHome(data);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const getAvailData = async () => {
    const availRequest = new DtlAvailResDtoList();
    try {
      setIsLoading(true);
      invokeRequest({
        methodDescriptor: HomeApi.DetailAvail,
        request: availRequest,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            const wrapper: WrapperDataRoom = {
              title: 'Vila Available List',
              data: data.listList,
            };
            setDataRoomDetail(wrapper);
            handleShow();
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const getCheckInData = async () => {
    const availRequest = new DtlCheckinResDtoList();
    try {
      setIsLoading(true);
      invokeRequest({
        methodDescriptor: HomeApi.DetailCheckin,
        request: availRequest,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            const wrapper: WrapperDataRoom = {
              title: 'Vila Checkin List',
              data: data.listList,
            };
            setDataRoomDetail(wrapper);
            handleShow();
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const getBookingData = async () => {
    const availRequest = new DtlBookedResDtoList();
    try {
      setIsLoading(true);
      invokeRequest({
        methodDescriptor: HomeApi.DetailBooked,
        request: availRequest,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            const wrapper: WrapperDataRoom = {
              title: 'Vila Booking List',
              data: data.listList,
            };
            setDataRoomDetail(wrapper);
            handleShow();
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const getCheckoutData = async () => {
    const availRequest = new DtlCheckoutResDtoList();
    try {
      setIsLoading(true);
      invokeRequest({
        methodDescriptor: HomeApi.DetailCheckout,
        request: availRequest,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            const wrapper: WrapperDataRoom = {
              title: 'Vila Checkout List',
              data: data.listList,
            };
            setDataRoomDetail(wrapper);
            handleShow();
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const getBlmLunasData = async () => {
    const availRequest = new DtlBlmLunasResDtoList();
    try {
      setIsLoading(true);
      invokeRequest({
        methodDescriptor: HomeApi.DetailBlmLunas,
        request: availRequest,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            const wrapper: WrapperDataRoom = {
              title: 'Vila Blm Lunas List',
              data: data.listList,
            };
            setDataRoomDetail(wrapper);
            handleShow();
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const setupAsyncData = async () => {
    await getHomeData();
  };

  const useMount = () => {
    setupAsyncData();
  };

  useEffect(useMount, []);

  const HomeValue = {
    isOpen,
    dataHome,
    user,
    dataRoomDetail,
    handleClose,
    getAvailData,
    getCheckInData,
    getBookingData,
    getCheckoutData,
    getBlmLunasData,
  };

  return HomeValue;
};
