import React, { useState } from 'react';

export const LoadingContext = React.createContext({});
export const LoadingActionsContext = React.createContext<
  ILoadingActionsContext
>({ setIsLoading: () => null });
export interface ISetIsLoadingContext {
  (data: boolean): void;
}

export interface ILoadingActionsContext {
  setIsLoading: ISetIsLoadingContext;
}

export const LoadingProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const loadingValue = { isLoading };
  const loadingActionsValue = { setIsLoading };

  return (
    <LoadingContext.Provider value={loadingValue}>
      <LoadingActionsContext.Provider value={loadingActionsValue}>
        {children}
      </LoadingActionsContext.Provider>
    </LoadingContext.Provider>
  );
};
