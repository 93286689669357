import React from 'react';
import ErrorBoundary from './utils/ErrorHandler';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingProvider } from './contexts/LoadingContext';
import AppRoutes from './components/AppRoutes/AppRoutes';
import PopupProvider from './contexts/PopupContext';
import { ToasterProvider } from './contexts/ToasterContext';
import ServiceProvider from './contexts/ServiceContext';

const App = () => (
  <ErrorBoundary>
    <LoadingProvider>
      <ToasterProvider>
        <PopupProvider>
          <AuthProvider>
            <ServiceProvider>
              <AppRoutes />
            </ServiceProvider>
          </AuthProvider>
        </PopupProvider>
      </ToasterProvider>
    </LoadingProvider>
  </ErrorBoundary>
);

export default App;
