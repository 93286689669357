import React from 'react';
import { AuthActionsContext, AuthContext } from '../../contexts/AuthContext';
import { LoadingActionsContext } from '../../contexts/LoadingContext';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import * as yup from 'yup';
import { AuthApi } from '../../protofile/auth/auth_pb_service';
import { grpc } from '@improbable-eng/grpc-web';
import { ChangePassReqDto } from '../../protofile/auth/auth_pb';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

interface IChangePassword {
  // oldpass: string;
  newpass: string;
  confpass: string;
}

const formInitialState: IChangePassword = {
  // oldpass: '',
  newpass: '',
  confpass: '',
};

const MySwal = withReactContent(Swal);

export const useChangePassword = () => {
  const history = useHistory();
  const { user } = React.useContext(AuthContext);
  const { logout } = React.useContext(AuthActionsContext);
  const { invokeRequest } = React.useContext<IServiceActions>(
    ServiceActionsContext,
  );
  const { showToast } = React.useContext(ToasterActionsContext);
  const { setIsLoading } = React.useContext(LoadingActionsContext);
  const [isWrongOldPass, setIsWrongOldPass] = React.useState(true);

  const formSchema = yup.object<IChangePassword>({
    // oldpass: yup.string().required('Password Lama is a required'),
    newpass: yup.string().required('Password Baru is a required'),
    confpass: yup
      .string()
      .required('Konfirmasi Password is a required')
      .oneOf([yup.ref('newpass'), ''], 'Passwords tidak sama!'),
  });

  const updatePassword = async ({ newpass }: IChangePassword) => {
    try {
      const changePassReq = new ChangePassReqDto();
      changePassReq.setUsername(user.username);
      changePassReq.setNewpass(newpass);
      invokeRequest({
        methodDescriptor: AuthApi.ChangePassword,
        request: changePassReq,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          setIsLoading(false);
          console.log(code, message);
          if (code === grpc.Code.Aborted) {
            MySwal.fire({
              title: 'Oops...',
              text: message,
              icon: 'warning',
            });
          }
          if (code === grpc.Code.Unknown) {
            MySwal.fire({
              title: 'Tidak dapat terhubung ke server',
              text: 'Silahkan periksa internet anda',
              timer: 2000,
              icon: 'error',
              timerProgressBar: true,
              willOpen: () => {
                Swal.showLoading()
              },
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            MySwal.fire({
              title: 'Ganti password berhasil',
              timer: 2000,
              icon: 'success',
              timerProgressBar: true,
              willOpen: () => {
                Swal.showLoading()
              },
              onClose: () => {
                logout().then(() => history.go(0));
              },
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const changePasswordState = {
    updatePassword,
    formInitialState,
    formSchema,
  };
  return changePasswordState;
};
