import { grpc } from '@improbable-eng/grpc-web';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import { AuthContext, IAuthContext } from '../../contexts/AuthContext';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TaxApi } from '../../protofile/tax/tax_pb_service';
import { CreateTaxReqDto, TaxDto, TaxListDto } from '../../protofile/tax/tax_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';

const MySwal = withReactContent(Swal);

export interface ITax {
  id: string;
  name: string;
  value: string;
}

const initialTax: ITax = {
  id: '',
  name: '',
  value: '',
};

export const useTaxList = () => {
  const history = useHistory();
  const [taxList, setTaxList] = useState<Array<ITax>>([initialTax]);
  const [taxSelected, setTaxSelected] = useState<ITax>(initialTax);

  const { showToast } = useContext(ToasterActionsContext);
  const { invokeRequest } = useContext<IServiceActions>(ServiceActionsContext);
  const { user } = useContext<IAuthContext>(AuthContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);

  const [isEdit, setisEdit] = useState(false);

  const getTaxList = async () => {
    try {
      invokeRequest({
        methodDescriptor: TaxApi.List,
        request: new TaxListDto(),
        onStart: () => {},
        onEnd: (code: grpc.Code) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setTaxList(data.listList);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const openFormModal = (id: string) => {
    setisEdit(true)
    if (taxSelected.id === id) {
      handleShow();
    } else {
      const request = new TaxDto();
      request.setId(id);
      try {
        invokeRequest({
          methodDescriptor: TaxApi.GetTaxByID,
          request: request,
          onStart: () => {},
          onEnd: (code: grpc.Code) => {
            if (code === grpc.Code.Unknown) {
              showToast({
                message: 'network error connection to server',
                toastLegend: 'ERROR',
              });
            }
          },
          onMessage: (message) => {
            const data: any = message.toObject();
            if (data) {
              setTaxSelected(data);
              handleShow();
            }
          },
        });
      } catch (error) {
        console.log(`Error ${error}`);
      }
    }
  };

  const createNewTax = async (data: ITax) => {
    try {
      const request = new CreateTaxReqDto();
      request.setName(data.name)
      request.setValue(data.value)
      invokeRequest({
        methodDescriptor: TaxApi.Create,
        request: request,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          console.log(code, message);
          if (code === grpc.Code.AlreadyExists) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Aborted) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Berhasil',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => {
              history.go(0);
            }, 1600);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const editTax = async (data: ITax) => {
    try {
      const request = new TaxDto();
      request.setId(data.id)
      request.setName(data.name)
      request.setValue(data.value)
      invokeRequest({
        methodDescriptor: TaxApi.Update,
        request: request,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          if (code === grpc.Code.AlreadyExists) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Aborted) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Berhasil',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => {
              history.go(0);
            }, 1600);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const deleteSelectedTax = (id: string) => {
    try {
      const request = new google_protobuf_wrappers_pb.StringValue();
      request.setValue(id);
      invokeRequest({
        methodDescriptor: TaxApi.DeleteByID,
        request: request,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          console.log(code, message);
          if (code === grpc.Code.PermissionDenied) {
            showToast({
              message: message,
              toastLegend: 'WARNING',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Berhasil',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => {
              history.go(0)
            }, 1600);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const deleteTax = async (id: string) => {
    MySwal.fire({
      title: 'Apakah Anda yakin?',
      text: `Menghapus ${id}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSelectedTax(id);
      }
    });
  };

  const addNewTaxModal = () => {
    setisEdit(false)
    setTaxSelected(initialTax);
    handleShow();
  };

  const setupAsyncData = async () => {
    await getTaxList();
  };

  const useMount = () => {
    setupAsyncData();
  };

  useEffect(useMount, []);

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    user.role === 'OPRT' && setRedirect(true);
  }, []);

  const useTaxListValue = {
    isEdit,
    isOpen,
    taxList,
    redirect,
    taxSelected,
    editTax,
    deleteTax,
    handleClose,
    createNewTax,
    openFormModal,
    addNewTaxModal,
  };

  return useTaxListValue;
};
