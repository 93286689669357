import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from '../../pages/Login/Login';
import Layout from '../Layouts/Layout';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import { PublicRoute } from '../PublicRoute/PublicRoute';



const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={() => console.log('Lagi Loading')}>
        <Switch>
          {/* Define public routing pages right here */}
          <PublicRoute
            defaultRoute
            path="/login"
            component={(props) => <Login {...props} />}
            metaTitle="Login"
          />
          <ProtectedRoute
            path="/"
            metaTitle="Dashboard"
            component={(props) => <Layout {...props} />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
