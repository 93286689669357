import { grpc } from '@improbable-eng/grpc-web';
import React from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { LoadingActionsContext } from '../../contexts/LoadingContext';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import { AuthApi } from '../../protofile/auth/auth_pb_service';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { EditUserReqDto } from '../../protofile/auth/auth_pb';
import * as yup from 'yup';

interface IUserProfile {
  username: string;
  email: string;
  mobile: string;
  role: string;
  status: string;
}

const formInitialState: IUserProfile = {
  username: '',
  email: '',
  mobile: '',
  role: '',
  status: '',
};
export const useProfile = () => {
  const { user } = React.useContext(AuthContext);
  const { invokeRequest } = React.useContext<IServiceActions>(
    ServiceActionsContext,
  );
  const { showToast } = React.useContext(ToasterActionsContext);
  const { setIsLoading } = React.useContext(LoadingActionsContext);
  const [onEdit, setOnEdit] = React.useState<boolean>(false);
  const toggleEdit = () => setOnEdit(!onEdit);
  const [dataUserProfile, setDataUserProfile] = React.useState<IUserProfile>(
    formInitialState,
  );

  const formSchema = yup.object<IUserProfile>({
    username: yup.string().required(),
    email: yup.string().required(),
    mobile: yup.string().required(),
    role: yup.string().required(),
    status: yup.string().required(),
  });

  const getSelfProfile = async () => {
    setIsLoading(true);
    try {
      const value = new wrappers.StringValue();
      value.setValue(user.username);
      invokeRequest({
        methodDescriptor: AuthApi.UserProfile,
        request: value,
        onStart: () => {},
        onEnd: (code: grpc.Code) => {
          setIsLoading(false);
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            setDataUserProfile(data);
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const updateSelfProfile = async ({
    username,
    email,
    mobile,
  }: IUserProfile) => {
    setIsLoading(true);
    try {
      const editUserReq = new EditUserReqDto();
      const mobileCatches = mobile.replace(/^0+/, '62');
      editUserReq.setUsername(username);
      editUserReq.setEmail(email);
      editUserReq.setMobile(mobileCatches);
      // editUserReq.setRole(role);
      // editUserReq.setStatus(status);
      invokeRequest({
        methodDescriptor: AuthApi.EditUser,
        request: editUserReq,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          setIsLoading(false);
          console.log(code, message);
          if (code === grpc.Code.AlreadyExists) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Aborted) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setIsLoading(false);
            setOnEdit(false);
            showToast({
              message: 'Profile updated!',
              toastLegend: 'SUCCESS',
            });
            // setDataUserProfile(data);
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(`Error: ${error.message}`);
    }
  };

  const setupAsyncData = async () => {
    await getSelfProfile();
  };

  const useMount = () => {
    setupAsyncData();
  };

  React.useEffect(useMount, []);

  const ProfileState = {
    onEdit,
    dataUserProfile,
    formSchema,
    updateSelfProfile,
    toggleEdit,
  };

  return ProfileState;
};
