import { grpc } from '@improbable-eng/grpc-web';
import { useContext, useEffect, useState } from 'react';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import { GetDateDto, RoomDto, UpdtPriceDto } from '../../protofile/room/room_pb';
import { RoomApi } from '../../protofile/room/room_pb_service';
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb"; 
import { useHistory } from 'react-router-dom';

export interface IVilla {
  id?: string;
  name?: string;
  order?: IOrder;
  price?: number;
}

export interface IOrder {
  orderstatus:string;
}

const initialVillaList: IVilla = {
  id: '',
  name: '',
  order: {
    orderstatus: '',
  },
  price: 0,
};

export const useVillaList = () => {
  const history = useHistory();
  const [villaList, setVillaList] = useState<Array<IVilla>>([initialVillaList]);
  const [villaSelected, setVillaSelected] = useState<IVilla>(initialVillaList);
  const { showToast } = useContext(ToasterActionsContext);
  const { invokeRequest } = useContext<IServiceActions>(ServiceActionsContext);
  const sessionSelectedDate = sessionStorage.getItem("villaListSelectedDate") !== null ? sessionStorage.getItem("villaListSelectedDate") : new Date();
  const [selectedDate, handleDateChange] = useState<Date | any>(sessionSelectedDate);
  sessionStorage.setItem("villaListSelectedDate", selectedDate);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);

  const getVillaList = async () => {
    try {
      const getDate = new google_protobuf_timestamp_pb.Timestamp();
            getDate.setSeconds(Math.round(new Date(selectedDate).getTime() / 1000));
            getDate.setNanos(0);
      const req = new GetDateDto();
      req.setDate(getDate);

      invokeRequest({
        methodDescriptor: RoomApi.ListRooms,
        request: req,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setVillaList(data.listList);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const openVillaDetail = (id: string) => {
    if (villaSelected.id === id) {
      handleShow();
    } else {
      const request = new RoomDto();
      request.setId(id);
      try {
        invokeRequest({
          methodDescriptor: RoomApi.GetRoomByID,
          request: request,
          onStart: () => {},
          onEnd: (code: grpc.Code) => {
            if (code === grpc.Code.Unknown) {
              showToast({
                message: 'network error connection to server',
                toastLegend: 'ERROR',
              });
            }
          },
          onMessage: (message) => {
            const data: any = message.toObject();
            if (data) {
              setVillaSelected(data);
              handleShow();
            }
          },
        });
      } catch (error) {
        console.log(`Error ${error}`);
      }
    }
  };

  const editPrice = async (data: IVilla) => {
    try {
      const request = new UpdtPriceDto();
      request.setId(data.id!)
      request.setPrice(data.price!)
      invokeRequest({
        methodDescriptor: RoomApi.UpdatePrice,
        request: request,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          if (code === grpc.Code.AlreadyExists) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Aborted) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Berhasil',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => {
              history.go(0);
            }, 1600);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const setupAsyncData = async () => {
    await getVillaList();
  };

  const useMount = () => {
    setupAsyncData();
  };

  useEffect(useMount, [selectedDate]);

  const getStatus = (orderstatus: string) => {
    let sts = "Available";
    switch (orderstatus) {
      case "AVAIL":
        sts = "Available";
        break;
      case "BOOKD":
        sts = "Booked";
        break;
      case "CHKIN":
        sts = "Check In";
        break;
      case "CHKOT":
        sts = "Check Out";
        break;
      case "BOKIN":
        sts = "Check In / Booked";
        break;
    
      default:
        sts = "Available";
        break;
    }

    return sts;
  }
  
  const getStatusBg = (orderstatus: string) => {
    let bg = "bg-light";
    switch (orderstatus) {
      case "AVAIL":
        bg = "bg-primary";
        break;
      case "BOOKD":
        bg = "bg-warning";
        break;
      case "CHKIN":
        bg = "bg-success";
        break;
      case "CHKOT":
        bg = "bg-danger";
        break;
      case "BOKIN":
        bg = "bg-info";
        break;
    
      default:
        bg = "bg-light";
        break;
    }

    return bg;
  }

  const useVillaListValue = {
    isOpen,
    villaList,
    selectedDate,
    villaSelected,
    getStatus,
    editPrice,
    handleClose,
    getStatusBg,
    openVillaDetail,
    handleDateChange,
  };

  return useVillaListValue;
};