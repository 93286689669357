import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { IVilla, useVillaList } from '../useVillaList';
import { Formik } from 'formik';
import * as yup from 'yup';

type IDetailVillaProps = {
  id?: string;
  name?: string;
  closeAction: VoidFunction;
  price?: number;
};

const formSchemaEdit = yup.object<IVilla>({
  name: yup.string().required(),
  price: yup.number().required(),
});

const DetailVilla = (props: IDetailVillaProps) => {
  const { editPrice } = useVillaList();
  const {
    id,
    name,
    closeAction,
    price,
  } = props;
  const [villaDetail, setVillaDetail] = useState<IVilla>({
    id,
    name,
    price,
  });

  return (
    <Row className="my-2">
      <Col md="12">
        <Formik
          enableReinitialize
          initialValues={villaDetail}
          validationSchema={formSchemaEdit}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await editPrice(values);
            setSubmitting(false);
            closeAction();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} controlId="nameCtrl">
                <Form.Label column sm={2}>
                  Nama Villa
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    disabled={true}
                    autoComplete="off"
                    name="name"
                    placeholder="Nama Villa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    isInvalid={!!errors.name && !!touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="nameCtrl">
                <Form.Label column sm={2}>
                  Harga (Rp)
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="price"
                    placeholder="Harga"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    isInvalid={!!errors.price && !!touched.price}
                    onKeyPress={(e: any) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.price}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <React.Fragment>
                <Button
                  variant="default"
                  className="float-right mr-2"
                  onClick={closeAction}
                >
                  Close
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  className="float-right mr-2"
                  disabled={isSubmitting && !errors}
                >
                  Simpan
                </Button>
              </React.Fragment>
            </form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default DetailVilla;
