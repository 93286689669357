import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

export interface IPopupState {
  isPopupOpen: boolean;
  title: string;
  message: string;
  isTokenExpired?: boolean;
}

export interface IPopupActions {
  openPopup: ({ title, message }: any) => void;
  closePopup: () => void;
  openPopupTokenExpired?: ({ title, message }: any) => void;
  saveChanges?: (data: any) => void;
}

const initialStatePopup: IPopupState = {
  isPopupOpen: false,
  title: '',
  message: '',
  isTokenExpired: false,
};

const reducerPopup = (state: IPopupState, action: any): IPopupState => {
  switch (action.type) {
    case 'OPEN_POPUP':
      return {
        ...state,
        isPopupOpen: true,
        title: action.title,
        message: action.message,
        isTokenExpired: false,
      };
    case 'CLOSE_POPUP':
      return {
        ...state,
        isPopupOpen: false,
        title: action.title,
        message: action.message,
        isTokenExpired: false,
      };
    case 'TOKEN_EXPIRED':
      return {
        ...state,
        isPopupOpen: true,
        title: action.title,
        message: action.message,
        isTokenExpired: true,
      };
    default:
      return initialStatePopup
  }
};

export const PopupContext = React.createContext<IPopupState>(initialStatePopup);
export const PopupActionsContext = React.createContext<IPopupActions>({
  openPopup: () => {},
  openPopupTokenExpired: () => {},
  closePopup: () => {},
  saveChanges: () => {},
});

const PopupProvider = ({ children }: any) => {
  const [
    { isPopupOpen, title, message, isTokenExpired },
    dispatchPopup,
  ] = useReducer(reducerPopup, initialStatePopup);

  const openPopup = ({ title, message }: any) => {
    dispatchPopup({
      type: 'OPEN_POPUP',
      title,
      message,
    });
  };

  const closePopup = () => {
    dispatchPopup({ type: 'CLOSE_POPUP' });
  };

  const openPopupTokenExpired = ({ title, message }: any) => {
    dispatchPopup({
      type: 'OPEN_POPUP_TOKEN_EXPIRED',
      title,
      message,
    });
  };

  const popupValue = {
    isPopupOpen,
    title,
    message,
    isTokenExpired,
  };

  const popupActionsValue:IPopupActions = {
    openPopup,
    closePopup,
    openPopupTokenExpired,
  };

  return (
    <PopupContext.Provider value={popupValue}>
      <PopupActionsContext.Provider value={popupActionsValue}>
        {children}
      </PopupActionsContext.Provider>
    </PopupContext.Provider>
  );
};

PopupProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PopupProvider;
