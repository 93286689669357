import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useBookingDetail } from './useBookingDetail';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const BookingDetail = () => {
  const {
    bookingDetail, formatBookingDate,
    cancelBooking, getStatusBg, getStatus,
    getStatusPay, lunasBooking
  } = useBookingDetail();

  const openAlertBookingCancel = (odn: string) => {
    MySwal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Cancel booking no pesanan ' + bookingDetail.ordernumber,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        cancelBooking(odn)
      }
    })
  }

  const openAlertBookingLunas = (odn: string) => {
    MySwal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Booking dengan No. Pesanan ' + bookingDetail.ordernumber + ' telah melunasi pembayaran',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        lunasBooking(odn)
      }
    })
  }

  const contentHeader = (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Booking Detail</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/booking">Booking</Link>
              </li>
              <li className="breadcrumb-item active">Booking Detail</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );

  if (bookingDetail.ordernumber === '')
    return (
      <>
        {contentHeader}
        <section className="content">
          <div className="col-lg-12 mb-2 ml-3">
            <Spinner animation="border" variant="secondary" />
          </div>
        </section>
      </>
    );

  return (
    <>
      {contentHeader}
      <section className="content">
        <div className="col-lg-12 mb-2">
          <Link className="mr-2 btn btn-secondary" to="/booking">Back</Link>
          {bookingDetail.status === 'CHKOT' ? null : (
            <Button className="mr-2" variant="danger" onClick={() => openAlertBookingCancel(bookingDetail.ordernumber)}>Cancel</Button>
          )}
          {bookingDetail.payment.status === 'LNS' ? null : (
            <Button className="mr-2" variant="warning" onClick={() => openAlertBookingLunas(bookingDetail.ordernumber)}>Lunas</Button>
          )}
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <Card>
                <Card.Header as="h5" className={getStatusBg(bookingDetail.status) + " text-bold text-center"}>{getStatus(bookingDetail.status)}</Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        No. Pesanan :
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control plaintext disabled value={bookingDetail.ordernumber} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        Atas Nama :
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control plaintext disabled value={bookingDetail.customer} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        Nama Perusahaan :
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control plaintext disabled value={bookingDetail.customercompany} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control plaintext disabled value={bookingDetail.customerphone} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        Tanggal Booking :
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control plaintext disabled
                          value={formatBookingDate(bookingDetail.startdate.seconds) + " s/d " + formatBookingDate(bookingDetail.enddate.seconds)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        Booked Villa :
                      </Form.Label>
                      <Col sm="10">
                        {bookingDetail.productList.map((x, index: number) => (
                          <div key={index}>
                            <Form.Control plaintext disabled value={x.name} />
                          </div>
                        ))}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        Pembayaran :
                      </Form.Label>
                      <Col sm="10">
                        <Form.Group as={Row}>
                          <Col sm="2">
                            <Form.Control plaintext disabled defaultValue="Total Pembayaran :" />
                          </Col>
                          <Col sm="10">
                            <Form.Control plaintext disabled
                              value={"Rp. " + bookingDetail.payment.totalpay.toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm="2">
                            <Form.Control plaintext disabled defaultValue="Bayar Dimuka (DP) :" />
                          </Col>
                          <Col sm="10">
                            <Form.Control plaintext disabled
                              value={"Rp. " + bookingDetail.payment.downpay.toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm="2">
                            <Form.Control plaintext disabled defaultValue="Tipe Pembayaran :" />
                          </Col>
                          <Col sm="10">
                            <Form.Control plaintext disabled value={bookingDetail.payment.type} />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm="2">
                            <Form.Control plaintext disabled defaultValue="Status Pembayaran :" />
                          </Col>
                          <Col sm="10">
                            <Form.Control plaintext disabled value={getStatusPay(bookingDetail.payment.status)} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="bg-light">
                        Pesanan Tambahan :
                      </Form.Label>
                      <Col sm="10">
                        {bookingDetail.additionalList.map((x, index: number) => (
                          <div key={index}>
                            <Form.Group as={Row}>
                              <Col sm="2">
                                <Form.Control plaintext disabled value={x.name} />
                              </Col>
                              <Col sm="10">
                                <Form.Control plaintext disabled value={"Rp. " + x.price.toLocaleString("de-DE", { minimumFractionDigits: 2 })} />
                              </Col>
                            </Form.Group>
                          </div>
                        ))}
                      </Col>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingDetail;
