import { grpc } from '@improbable-eng/grpc-web';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import { ReportApi } from '../../protofile/report/report_pb_service';
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";

export interface IBookingDetail {
  ordernumber: string;
  productList: Array<IProductList>;
  customer: string;
  customercompany: string;
  customerphone: string;
  payment: IPayment;
  status: string;
  startdate: IBookingDate;
  enddate: IBookingDate;
  additionalList: Array<IAdditional>;
}

export interface IProductList {
  id: string;
  name: string;
  type: string;
  price: number;
}

export interface IBookingDate {
  seconds: number;
  nanos: number;
}

export interface IPayment {
  downpay: number;
  totalpay: number;
  status: string;
  type: string;
}

export interface IAdditional {
  id: string;
  name: string;
  price: number;
  deliv: boolean;
}

const initialBookingDetail: IBookingDetail = {
  ordernumber: '',
  productList: [{
    id: '',
    name: '',
    type: '',
    price: 0,
  }],
  customer: '',
  customercompany: '',
  customerphone: '',
  payment: {
    downpay: 0,
    totalpay: 0,
    status: '',
    type: '',
  },
  status: '',
  startdate: {
    seconds: 0,
    nanos: 0,
  },
  enddate: {
    seconds: 0,
    nanos: 0,
  },
  additionalList: [{
    id: '',
    name: '',
    price: 0,
    deliv: false,
  }]
};

export const useBookingDetail = () => {
  const [bookingDetail, setBookingDetail] = useState<IBookingDetail>(initialBookingDetail);
  const { showToast } = useContext(ToasterActionsContext);
  const { invokeRequest } = useContext<IServiceActions>(ServiceActionsContext);
  const param: any = useParams();
  const history = useHistory();

  const getBookingDetail = async () => {
    const request = new google_protobuf_wrappers_pb.StringValue;
    request.setValue(param.orderNumber)
    try {
      invokeRequest({
        methodDescriptor: ReportApi.DetailBooking,
        request: request,
        onStart: () => { },
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
        ) => {
          console.log(code, message);
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setBookingDetail(data);
          }
        },
      });
    } catch (error) {
      console.log(`Error ${error}`);
    }
  };

  const setupAsyncData = async () => {
    await getBookingDetail();
  };

  const useMount = () => {
    setupAsyncData();
  };

  useEffect(useMount, []);

  const cancelBooking = (ordNo: string) => {
    const request = new google_protobuf_wrappers_pb.StringValue;
    request.setValue(ordNo)
    try {
      invokeRequest({
        methodDescriptor: ReportApi.CancelBooking,
        request: request,
        onStart: () => { },
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          console.log(code, message);
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Cancel booking berhasil',
              toastLegend: 'SUCCESS',
            });
            history.push("/booking");
          }
        },
      });
    } catch (error) {
      console.log(`Error ${error}`);
    }
  };

  const lunasBooking = (ordNo: string) => {
    const request = new google_protobuf_wrappers_pb.StringValue;
    request.setValue(ordNo)
    try {
      invokeRequest({
        methodDescriptor: ReportApi.Lunas,
        request: request,
        onStart: () => { },
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          console.log(code, message);
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Booking telah lunas',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => history.go(0), 1600);
          }
        },
      });
    } catch (error) {
      console.log(`Error ${error}`);
    }
  };

  const getStatusBg = (status: string) => {
    let bg = "bg-light";
    switch (status) {
      case "AVAIL":
        bg = "bg-danger";
        break;
      case "BOOKD":
        bg = "bg-warning";
        break;
      case "CHKIN":
        bg = "bg-success";
        break;
      case "CHKOT":
        bg = "bg-danger";
        break;
      default:
        bg = "bg-light";
    }
    return bg;
  };

  const getStatus = (orderstatus: string) => {
    let sts = "Available";
    switch (orderstatus) {
      case "AVAIL":
        sts = "Available";
        break;
      case "BOOKD":
        sts = "Booked";
        break;
      case "CHKIN":
        sts = "Check In";
        break;
      case "CHKOT":
        sts = "Check Out";
        break;

      default:
        sts = "Available";
        break;
    }

    return sts;
  }

  const getStatusPay = (paystatus: string) => {
    let sts = "-";
    switch (paystatus) {
      case "LNS":
        sts = "Lunas";
        break;
      case "DDP":
        sts = "Dengan DP";
        break;
      case "TDP":
        sts = "Tanpa DP";
        break;

      default:
        sts = "-";
        break;
    }

    return sts;
  }

  const formatBookingDate = (seconds: number) => {
    let bookingdate = new Date(0); // Epoch
    bookingdate.setSeconds(seconds + 25200);
    const months = [
      "Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"
    ];
    let stringBookingDate = bookingdate.getDate() + " " + months[bookingdate.getMonth()] + " " + bookingdate.getFullYear()
    return stringBookingDate;
  };

  const useBookingDetailValue = {
    bookingDetail, formatBookingDate,
    cancelBooking, getStatusBg, getStatus,
    getStatusPay, lunasBooking
  };

  return useBookingDetailValue;
};
