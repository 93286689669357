import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { grpc } from '@improbable-eng/grpc-web';
import * as jspb from 'google-protobuf';
import { AuthHeader, DevTools } from '../utils/SharedFunction';
import appConfig from '../config/application';

interface IServiceContext {
  (payload: grpcPayload<jspb.Message, jspb.Message>): void;
}
export interface IServiceActions {
  invokeRequest: IServiceContext;
}

export const ServiceActionsContext = React.createContext<IServiceActions>({
  invokeRequest: () => {},
});

type grpcPayload<
  RequestType extends jspb.Message,
  ResponseType extends jspb.Message
> = {
  methodDescriptor: grpc.MethodDefinition<RequestType, ResponseType>;
  transport?: grpc.Transport;
  debug?: boolean;
  host?: string;
  request: RequestType;
  onStart: () => any | void;
  onHeaders?: (headers: grpc.Metadata) => any | void;
  onMessage?: (res: ResponseType) => any | void;
  onEnd: (
    code: grpc.Code,
    message: string,
    trailers: grpc.Metadata,
  ) => any | void;
};

const ServiceProvider: FC = ({ children }) => {
  // const { logout } = useContext(AuthActionsContext);

  const invokeRequest = async (
    payload: grpcPayload<jspb.Message, jspb.Message>,
  ) => {
    let configMetadata = {
      ...AuthHeader(),
      'Content-Type': 'application/grpc-web+proto',
    };

    if (!!payload.onStart) {
      payload.onStart();
    }

    try {
      grpc.invoke(payload.methodDescriptor, {
        debug: payload.debug,
        host: payload.host || appConfig.endpoint.domain,
        request: payload.request,
        metadata: {
          ...configMetadata,
        },
        // transport: payload.transport,
        onHeaders: (headers: grpc.Metadata) => {
          if (!payload.onHeaders) return;
          return payload.onHeaders(headers);
        },
        onMessage: (res: jspb.Message) => {
          if (!payload.onMessage) return;
          return payload.onMessage(res);
        },
        onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
          // if(code === grpc.Code.Unknown) { throw Error(message) }
          return payload.onEnd(code, message, trailers);
        },
      });
    } catch (error) {
      DevTools(() => console.log('Error: ', error.message));
    }
  };

  const serviceActionsValue: IServiceActions = {
    invokeRequest,
  };

  return (
    <ServiceActionsContext.Provider value={serviceActionsValue}>
      {children}
    </ServiceActionsContext.Provider>
  );
};

ServiceProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ServiceProvider;
