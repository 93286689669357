import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import AppModal from '../../components/Modal/AppModal';
import TaxModal from './Form/TaxModal';
import { useTaxList } from './useTaxList';

const Tax = () => {
  const {
    isEdit,
    isOpen,
    taxList,
    redirect,
    taxSelected,
    deleteTax,
    handleClose,
    openFormModal,
    addNewTaxModal,
  } = useTaxList();

  function redirectTo() {
    return <Redirect to="/" />;
  }

  if (redirect) return redirectTo();
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>List Pajak</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">List Pajak</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Card
                className="text-center"
                style={{
                  height: '120px',
                  cursor: "pointer"
                }}
                onClick={addNewTaxModal}
              >
                <Card.Body>
                  <i style={{ fontSize: '30px' }} className="fas fa-plus" />
                </Card.Body>
                <Card.Footer>
                  <span className="text-center text-bold">Tambah Tax</span>
                </Card.Footer>
              </Card>
            </div>
            {taxList.map((x, index: number) => (
              <div className="col-lg-2" key={index}>
                <Card
                  style={{ height: '120px' }}
                  className="text-center"
                >
                  <Card.Body className="text-bold">
                    {x.name}:&ensp;{x.value}%
                  </Card.Body>
                  <Card.Footer>
                    <div className="row">
                      <div className="col-6">
                        <Card.Link style={{ cursor: "pointer" }} onClick={() => openFormModal(x.id!)}>
                          <i className="fas fa-edit text-info"></i>
                        </Card.Link>
                      </div>
                      <div className="col-6">
                        <Card.Link style={{ cursor: "pointer" }} onClick={() => deleteTax(x.id!)}>
                          <i className="fas fa-trash text-danger" />
                        </Card.Link>
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <AppModal
        title={taxSelected.id ? 'Edit Pajak' : 'Buat Pajak'}
        isOpen={isOpen}
        closeAction={handleClose}
        hasCustomButton={true}
      >
        <TaxModal
          {...taxSelected}
          closeAction={handleClose}
          isEdit={isEdit}
        />
      </AppModal>
    </>
  );
};

export default Tax;
