import React, { useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Booking from '../../pages/Booking/Booking';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import BookingDetail from '../../pages/Booking/Detail';
import Home from '../../pages/Home/Home';
import Profile from '../../pages/Profile/Profile';
import User from '../../pages/User/User';
import Villa from '../../pages/Villa/Villa';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import Toast from '../Toast/Toast';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { useMedia } from 'react-media';
import Report from '../../pages/Report/Report';
import Tax from '../../pages/Tax/Tax';

declare const document: any;

const Layout = (props: any) => {
  const { ...rest } = props;
  const [menusidebarState, updateMenusidebarState] = useState({
    isMenuSidebarCollapsed: false,
  });

  const isMobileView = useMedia({ query: '(max-width: 599px)' });

  const { user } = React.useContext(AuthContext);

  const toggleMenuSidebar = () => {
    updateMenusidebarState({
      isMenuSidebarCollapsed: !menusidebarState.isMenuSidebarCollapsed,
    });
  };
  document.getElementById('root').className += ' sidebar-mini';

  // Make some a calling effect twice
  React.useEffect(() => {
    if (isMobileView) {
      updateMenusidebarState({ isMenuSidebarCollapsed: true });
    } else {
      updateMenusidebarState({ isMenuSidebarCollapsed: false });
    }
  }, [isMobileView, rest.location.pathname]);

  if (menusidebarState.isMenuSidebarCollapsed) {
    document.getElementById('root').classList.add('sidebar-collapse');
    document.getElementById('root').classList.remove('sidebar-open');
  } else {
    document.getElementById('root').classList.add('sidebar-open');
    document.getElementById('root').classList.remove('sidebar-collapse');
  }

  return (
    <div className="wrapper">
      <Header
        toggleMenuSidebar={toggleMenuSidebar}
        username={user.username ?? '-'}
      />
      <Sidebar />
      <div className="content-wrapper">
        <div className="pt-3" />
        <section className="content">
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              metaTitle="Dashboard"
              component={(props) => <Home {...props} />}
            />
            <ProtectedRoute
              exact
              path="/villa"
              metaTitle="Villa"
              component={(props) => <Villa {...props} />}
            />
            <ProtectedRoute
              exact
              path="/booking"
              metaTitle="Booking"
              component={(props) => <Booking {...props} />}
            />
            <ProtectedRoute
              exact
              path="/user"
              metaTitle="User List"
              component={(props) => <User {...props} />}
            />
            <ProtectedRoute
              exact
              path="/profile"
              metaTitle="Profile Detail"
              component={(props) => <Profile {...props} />}
            />
            <ProtectedRoute
              exact
              path="/profile/change-password"
              metaTitle="Change Password"
              component={(props) => <ChangePassword {...props} />}
            />
            <ProtectedRoute
              exact
              path="/booking/detail/:orderNumber"
              metaTitle="Detail Booking"
              component={(props) => <BookingDetail {...props} />}
            />
            <ProtectedRoute
              exact
              path="/booking/detail/"
              metaTitle="Detail Booking"
              component={() => <Redirect to="/" />}
            />
            <ProtectedRoute
              exact
              path="/report"
              metaTitle="Report"
              component={(props) => <Report {...props} />}
            />
            <ProtectedRoute
              exact
              path="/tax"
              metaTitle="Tax"
              component={(props) => <Tax {...props} />}
            />
          </Switch>
          <Toast />
          {/* <Popup /> */}
        </section>
      </div>
      <Footer />
      <div
        id="sidebar-overlay"
        role="presentation"
        onClick={toggleMenuSidebar}
        onKeyDown={() => {}}
      />
    </div>
  );
};

export default Layout;
