import React, { useContext } from 'react';
import NavItem from '../../NavItem/NavItem';
import sidebarLogo from '../../../assets/rasamala/rasamala-sidebar.png';
import { IAuthContext, AuthContext } from '../../../contexts/AuthContext';

const Sidebar = (props: any) => {
  const { user } = useContext<IAuthContext>(AuthContext);
  
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a className="brand-link">
        <img
          src={sidebarLogo}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: '.8' }}
        />
        <span className="brand-text font-weight-light">PondokRasamala</span>
      </a>
      <div className="sidebar">
        {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <a href="#" className="d-block">
              Alexander Pierce
            </a>
          </div>
        </div> */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* <li className="nav-item has-treeview menu-open">
              <a href="#" className="nav-link active">
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>
                  Starter Pages
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a href="#" className="nav-link active">
                    <i className="far fa-circle nav-icon" />
                    <p>Active Page</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Inactive Page</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="nav-icon fas fa-th" />
                <p>
                  Simple Link
                  <span className="right badge badge-danger">New</span>
                </p>
              </a>
            </li> */}

            {/* <li className="nav-item">
              <NavLink to="/" exact className="nav-link">
                <i className="nav-icon fas fa-home" />
                <p>Home</p>
              </NavLink>
              <Link to="/villa" className="nav-link">
                <i className="nav-icon fas fa-building" />
                <p>Villa</p>
              </Link>
              <Link to="/booking" className="nav-link">
                <i className="nav-icon fas fa-bed" />
                <p>Booking</p>
              </Link>
              <Link to="/user" className="nav-link">
                <i className="nav-icon fas fa-user-plus" />
                <p>User</p>
              </Link>
            </li> */}
            <NavItem path="/" title="Home" icon="nav-icon fas fa-home" />
            <NavItem
              path="/villa"
              title="Villa"
              icon="nav-icon fas fa-building"
            />
            <NavItem
              path="/booking"
              title="Booking"
              icon="nav-icon fas fa-bed"
            />
            {user.role === 'OWNR' || user.role === 'SADM' && (
            <NavItem
              path="/user"
              title="User"
              icon="nav-icon fas fa-user-plus"
            />
            )}
            {user.role === 'OWNR' || user.role === 'SADM' && (
            <NavItem
              path="/tax"
              title="Pajak"
              icon="nav-icon fas fa-money-bill"
            />
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
