import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useBookingList } from './useBookingList';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import '../../assets/tumbleweed.css';

const Booking = () => {
  const { 
    bookingList, openBookingDetail, 
    getWhatsAppURL, getMobileURL,
    formatBookingDate, getStatusBg,
    selectedDate, handleDateChange,
  } = useBookingList();

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Booking List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Booking List</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-5 mt-3 ml-3">
            <div className="col-lg-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Pilih Tanggal"
                  value={selectedDate}
                  onChange={handleDateChange}
                  animateYearScrolling
                  autoOk
                  format="dd MMMM yyyy"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {!bookingList.length && (
              <div className="col-lg-12">
                <div className="empty-icon-container">
                  <div className="animation-container">
                    <div className="bounce"></div>
                    <div className="pebble1"></div>
                    <div className="pebble2"></div>
                    <div className="pebble3"></div>
                  </div>
                  <div>
                    <h2 className="mt-4">0 Booking</h2>
                    <p>Maaf, Booking tidak ditemukan</p>
                  </div>
                </div>
              </div>
            )}
            {bookingList.map((x, index: number) => (
              <div
                className="col-lg-2"
                key={index}
                onClick={() => openBookingDetail(x.ordernumber)}
              >
                <Card className="text-center">
                  <p style={{ transform: `rotate(0)` }}>
                    <Card.Body>
                      <Link className="stretched-link" to={'/booking/detail/'+x.ordernumber}></Link>
                      <div className="col-12 text-bold">
                        {x.customer.name} - {x.customer.company}
                      </div>
                      <div className="col-12 text-sm-center font-italic">
                        {x.ordernumber}
                      </div>
                      <div className="col-12 text-sm-center font-italic">
                        {formatBookingDate(x.startdate.seconds)} <br/>
                          s/d <br/>
                        {formatBookingDate(x.enddate.seconds)}
                      </div>
                    </Card.Body>
                  </p>
                  <Card.Footer className={getStatusBg(x.status)}>
                    <div className="row">
                      <div className="col-12">
                        {x.customer.phone}
                      </div>
                      <div className="col-6">
                        <Card.Link href={getWhatsAppURL(x.customer.phone)} target="_blank">
                          <i className="fab fa-whatsapp text-light"></i>
                        </Card.Link>
                      </div>
                      <div className="col-6">
                        <Card.Link href={getMobileURL(x.customer.phone)} target="_blank">
                          <i className="fas fa-phone-alt text-light"></i>
                        </Card.Link>
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Booking;
