import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ITax, useTaxList } from '../useTaxList';
import { Formik } from 'formik';
import * as yup from 'yup';

type IFormTaxProps = {
  closeAction: VoidFunction;
  isEdit?: boolean;
  id: string;
  name: string;
  value: string;
};

const formSchema = yup.object<ITax>({
  id: yup.string(),
  name: yup.string().required(),
  value: yup.string().required(),
});

const TaxModal = (props: IFormTaxProps) => {
  const {createNewTax, editTax} = useTaxList();
  const {
    closeAction,
    id,
    name,
    value,
    isEdit,
  } = props;
  const [taxFormModal] = useState<ITax>({
    id,
    name,
    value,
  });

  return (
    <Row className="my-2">
      <Col md="12">
        <Formik
          enableReinitialize
          initialValues={taxFormModal}
          validationSchema={formSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            !isEdit ? await createNewTax(values) : await editTax(values);
            setSubmitting(false);
            closeAction();
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <input type="text" name="id" value={values.id} hidden />
              <Form.Group as={Row} controlId="nameCtrl">
                <Form.Label column sm={2}>
                  Nama Pajak
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="name"
                    placeholder="Nama Pajak"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    isInvalid={!!errors.name && !!touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="valueCtrl">
                <Form.Label column sm={2}>
                  Nilai Pajak
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="value"
                    placeholder="Nilai Pajak"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.value}
                    isInvalid={!!errors.value && !!touched.value}
                    onKeyPress={(e: any) => {
                      var rgx = /^[0-9]*\.?[0-9]*$/;
                      if (!rgx.test(e.key) && e.key !== 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.value}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <React.Fragment>
                <Button
                  variant="default"
                  className="float-right mr-2"
                  onClick={closeAction}
                >
                  Close
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  className="float-right mr-2"
                  disabled={isSubmitting && !errors}
                >
                  Simpan
                </Button>
              </React.Fragment>
            </form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default TaxModal;
