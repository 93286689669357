import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext, IAuthContext } from '../../contexts/AuthContext';
import PropTypes from 'prop-types';

export const PublicRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  
  const { user } = useContext<IAuthContext>(AuthContext);
  
  const useUpdateRoute = () => {
    window.scrollTo(0, 0);
    document.title = `${rest.metaTitle} - PondokRasamala | Resort Bernuansa Alam Pedesaan`;
  };

  useEffect(useUpdateRoute, [rest.location.pathname]);

  if (!user) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return <Redirect to={{ pathname: '/' }} />;
};

PublicRoute.propTypes = {
  defaultRoute: PropTypes.bool,
  exact: PropTypes.bool,
  path: PropTypes.string,
  metaTitle: PropTypes.string,
  component: PropTypes.func.isRequired,
};