import { grpc } from '@improbable-eng/grpc-web';
import {
  ChangeEvent,
  FormEvent,
  ILoginPayload,
  ILoginState,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AuthActionsContext,
  IAuthActionsContext,
} from '../../contexts/AuthContext';
import {
  ILoadingActionsContext,
  LoadingActionsContext,
} from '../../contexts/LoadingContext';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import { LoginDto } from '../../protofile/auth/auth_pb';
import { AuthApi } from '../../protofile/auth/auth_pb_service';

export const useLogin = () => {
  const { showToast } = useContext(ToasterActionsContext);
  const { login } = useContext<IAuthActionsContext>(AuthActionsContext);
  const { invokeRequest } = useContext<IServiceActions>(ServiceActionsContext);
  const { setIsLoading } = useContext<ILoadingActionsContext>(
    LoadingActionsContext,
  );

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const submitLogin = (payload:ILoginPayload) => async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<any> => {
    event.preventDefault();

    if (!isDisabled) {
      const loginRequest = new LoginDto();
      loginRequest.setUsername(payload.username);
      loginRequest.setPassword(payload.password);
      loginRequest.setDeviceid('xxx');
      try {
        invokeRequest({
          methodDescriptor: AuthApi.Login,
          request: loginRequest,
          onStart: () => {},
          onEnd: (
            code: grpc.Code,
            message: string | undefined,
            trailers: grpc.Metadata,
          ): any | void => {
            if (code === grpc.Code.FailedPrecondition) {
              showToast({
                message: message ?? '',
                toastLegend: 'WARNING',
              });
            }
            if (code === grpc.Code.Unknown) {
              showToast({
                message: 'network error connection to server',
                toastLegend: 'ERROR',
              });
            }
            if (
              code === grpc.Code.InvalidArgument &&
              message === 'invalid password'
            ) {
              showToast({
                message: 'wrong password, please try again!',
                toastLegend: 'ERROR',
              });
            }
            if (code === grpc.Code.NotFound) {
              showToast({
                message: 'account not registered',
                toastLegend: 'ERROR',
              });
            }
            return;
          },
          onMessage: (message) => {
            const user: any = message.toObject();
            if (user) {
              login(user);
            }
            return;
          },
        });
      } catch (error) {
        console.error(`Error: ${error.message}`);
      }

      setIsLoading(false);
    }
  };

  const handleChange = (str: string) => (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    if (str === 'username') {
      setUsername(event.target.value);
    } else if (str === 'password') {
      setPassword(event.target.value);
    }
  };

  const checkInputValidity = (): void => {
    if (!username || !password) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const useCheckInputValidity = (): void => {
    checkInputValidity();
  };

  const useLoginValue: ILoginState = {
    username,
    password,
    isDisabled,
    submitLogin,
    handleChange,
  };

  useEffect(useCheckInputValidity, [username, password]);

  return useLoginValue;
};
