import React from 'react';
import { NavLink } from 'react-router-dom';

type NavItemProps = React.PropsWithChildren<{
  icon?: string;
  title: String;
  path: string;
}>;

const NavItem = ({ path, icon, title, children }: NavItemProps) => {
  return (
    <li className="nav-item">
      <NavLink exact to={path} className="nav-link">
        <i className={icon} />
        <p>
          {title}
          {children}
          {/* <span className="right badge badge-danger">New</span> */}
        </p>
      </NavLink>
    </li>
  );
};

export default NavItem;
