import React, { HTMLAttributes } from 'react';

interface IBoxInfo {
  type: string;
  icon?: string;
  count: any;
  title: string;
  navigateTo: VoidFunction;
  customStyle?: HTMLAttributes<HTMLDivElement>;
}
const BoxInfo = (props: IBoxInfo) => {
  const { type, count, title, navigateTo, customStyle } = props;

  let className = 'small-box';
  // let iconClass = 'ion';
  switch (type) {
    case 'success':
      className += ' bg-success';
      break;
    case 'warning':
      className += ' bg-warning';
      break;
    case 'danger':
      className += ' bg-danger';
      break;
    case 'info':
      className += ' bg-info';
      break;
    case 'purple':
      className += ' bg-purple';
      break;
    default:
      className += ' bg-info';
      break;
  }

  // iconClass += icon ? ` ${icon}` : ' ion-bag';

  return (
    <div className={className} style={customStyle}>
      <div className="inner">
        <h3>{count}</h3>
        <p>{title}</p>
      </div>
      {/* <div className="icon">
        <i className={iconClass} />
      </div> */}
      <a
        style={{ cursor: 'pointer' }}
        onClick={navigateTo}
        className="small-box-footer"
      >
        <span className="mr-2">More info</span>
        <i className="fa fa-arrow-circle-right" />
      </a>
    </div>
  );
};

// BoxInfo.propTypes = {
//   type: PropTypes.oneOf(['success', 'warning', 'danger', 'info', 'purple']),
//   icon: PropTypes.string,
//   count: PropTypes.number.isRequired,
//   title: PropTypes.string.isRequired,
//   navigateTo: PropTypes.any,
//   customStyle: PropTypes.any,
// };

// BoxInfo.defaultProps = {
//   type: 'info',
//   icon: 'ion-bag',
// };

export default BoxInfo;
