import Constants from './Constants';

declare const window: any;

export const AuthHeader = () => {
  const loginObject: any = localStorage.getItem(Constants.storageKey);
  const parsedLoginObject = JSON.parse(loginObject);
  if (!!parsedLoginObject && parsedLoginObject !== {}) {
    // TODO: change with example bearer call right here
    return {
      Authorization: `Bearer ${parsedLoginObject.accesstoken}`,
    };
  }
  return '';
};
export const DevTools = (callback: Function) => {
  if (!!window && window.GRPC_DASHBOARD.environment === 'development')
    callback();
};

enum UserRole {
  OPRT = 'OPRT',
  OWNR = 'OWNR',
  SADM = 'SADM',
}

export const RoleToString = (role: string) => {
  switch (role) {
    case UserRole.OPRT:
      return 'Operator';
    case UserRole.OWNR:
      return 'Owner';
    case UserRole.SADM:
      return 'Super Admin';
    default:
      return '';
  }
};

export const RoleToEnum = (role: string) => {
  switch (role) {
    case 'Operator':
      return UserRole.OPRT;
    case 'Owner':
      return UserRole.OWNR;
    case 'Super Admin':
      return UserRole.SADM;
    default:
      return '';
  }
};
