// source: report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.protoapi.BookingDto', null, global);
goog.exportSymbol('proto.protoapi.CheckInDto', null, global);
goog.exportSymbol('proto.protoapi.CheckInDtoList', null, global);
goog.exportSymbol('proto.protoapi.CheckOutDto', null, global);
goog.exportSymbol('proto.protoapi.ChecklistDelivReqDto', null, global);
goog.exportSymbol('proto.protoapi.CreateTaxReqDto', null, global);
goog.exportSymbol('proto.protoapi.CustomerDto', null, global);
goog.exportSymbol('proto.protoapi.DetailBookingDto', null, global);
goog.exportSymbol('proto.protoapi.EditBookingReqDto', null, global);
goog.exportSymbol('proto.protoapi.EditBookingResDto', null, global);
goog.exportSymbol('proto.protoapi.GenerateReportDto', null, global);
goog.exportSymbol('proto.protoapi.GenerateReportReqDto', null, global);
goog.exportSymbol('proto.protoapi.GenerateReportResDto', null, global);
goog.exportSymbol('proto.protoapi.GetDateLRsvDto', null, global);
goog.exportSymbol('proto.protoapi.GroupBookingDto', null, global);
goog.exportSymbol('proto.protoapi.GroupReportDtoList', null, global);
goog.exportSymbol('proto.protoapi.LunasResDto', null, global);
goog.exportSymbol('proto.protoapi.OrdAddDto', null, global);
goog.exportSymbol('proto.protoapi.OrdAddDtoList', null, global);
goog.exportSymbol('proto.protoapi.PaymentDto', null, global);
goog.exportSymbol('proto.protoapi.ProductDto', null, global);
goog.exportSymbol('proto.protoapi.ReportDto', null, global);
goog.exportSymbol('proto.protoapi.ReportDtoList', null, global);
goog.exportSymbol('proto.protoapi.ReservationDto', null, global);
goog.exportSymbol('proto.protoapi.ReservationDtoList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.CreateTaxReqDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.CreateTaxReqDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.CreateTaxReqDto.displayName = 'proto.protoapi.CreateTaxReqDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.ReportDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.ReportDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.ReportDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.ReportDto.displayName = 'proto.protoapi.ReportDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.ProductDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.ProductDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.ProductDto.displayName = 'proto.protoapi.ProductDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.ReservationDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.ReservationDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.ReservationDto.displayName = 'proto.protoapi.ReservationDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.CustomerDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.CustomerDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.CustomerDto.displayName = 'proto.protoapi.CustomerDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.PaymentDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.PaymentDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.PaymentDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.PaymentDto.displayName = 'proto.protoapi.PaymentDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.BookingDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.BookingDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.BookingDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.BookingDto.displayName = 'proto.protoapi.BookingDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.CheckInDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.CheckInDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.CheckInDto.displayName = 'proto.protoapi.CheckInDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.CheckOutDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.CheckOutDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.CheckOutDto.displayName = 'proto.protoapi.CheckOutDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.ReportDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.ReportDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.ReportDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.ReportDtoList.displayName = 'proto.protoapi.ReportDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.ReservationDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.ReservationDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.ReservationDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.ReservationDtoList.displayName = 'proto.protoapi.ReservationDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.CheckInDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.CheckInDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.CheckInDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.CheckInDtoList.displayName = 'proto.protoapi.CheckInDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.OrdAddDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.OrdAddDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.OrdAddDto.displayName = 'proto.protoapi.OrdAddDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.OrdAddDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.OrdAddDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.OrdAddDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.OrdAddDtoList.displayName = 'proto.protoapi.OrdAddDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GetDateLRsvDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.GetDateLRsvDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GetDateLRsvDto.displayName = 'proto.protoapi.GetDateLRsvDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GroupBookingDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.GroupBookingDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.GroupBookingDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GroupBookingDto.displayName = 'proto.protoapi.GroupBookingDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GroupReportDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.GroupReportDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.GroupReportDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GroupReportDtoList.displayName = 'proto.protoapi.GroupReportDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DetailBookingDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.DetailBookingDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.DetailBookingDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DetailBookingDto.displayName = 'proto.protoapi.DetailBookingDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.ChecklistDelivReqDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.ChecklistDelivReqDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.ChecklistDelivReqDto.displayName = 'proto.protoapi.ChecklistDelivReqDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.LunasResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.LunasResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.LunasResDto.displayName = 'proto.protoapi.LunasResDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.EditBookingReqDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.EditBookingReqDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.EditBookingReqDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.EditBookingReqDto.displayName = 'proto.protoapi.EditBookingReqDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.EditBookingResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.EditBookingResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.EditBookingResDto.displayName = 'proto.protoapi.EditBookingResDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GenerateReportDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.GenerateReportDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.GenerateReportDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GenerateReportDto.displayName = 'proto.protoapi.GenerateReportDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GenerateReportReqDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.GenerateReportReqDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GenerateReportReqDto.displayName = 'proto.protoapi.GenerateReportReqDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GenerateReportResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.GenerateReportResDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.GenerateReportResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GenerateReportResDto.displayName = 'proto.protoapi.GenerateReportResDto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.CreateTaxReqDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.CreateTaxReqDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.CreateTaxReqDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CreateTaxReqDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.CreateTaxReqDto}
 */
proto.protoapi.CreateTaxReqDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.CreateTaxReqDto;
  return proto.protoapi.CreateTaxReqDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.CreateTaxReqDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.CreateTaxReqDto}
 */
proto.protoapi.CreateTaxReqDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.CreateTaxReqDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.CreateTaxReqDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.CreateTaxReqDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CreateTaxReqDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.protoapi.CreateTaxReqDto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.CreateTaxReqDto} returns this
 */
proto.protoapi.CreateTaxReqDto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.protoapi.CreateTaxReqDto.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.CreateTaxReqDto} returns this
 */
proto.protoapi.CreateTaxReqDto.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.ReportDto.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.ReportDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.ReportDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.ReportDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReportDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    product: (f = msg.getProduct()) && proto.protoapi.ProductDto.toObject(includeInstance, f),
    reservation: (f = msg.getReservation()) && proto.protoapi.ReservationDto.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && proto.protoapi.CustomerDto.toObject(includeInstance, f),
    payment: (f = msg.getPayment()) && proto.protoapi.PaymentDto.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: jspb.Message.getFieldWithDefault(msg, 9, ""),
    updatedby: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    additionalList: jspb.Message.toObjectList(msg.getAdditionalList(),
    proto.protoapi.OrdAddDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.ReportDto}
 */
proto.protoapi.ReportDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.ReportDto;
  return proto.protoapi.ReportDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.ReportDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.ReportDto}
 */
proto.protoapi.ReportDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.protoapi.ProductDto;
      reader.readMessage(value,proto.protoapi.ProductDto.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 3:
      var value = new proto.protoapi.ReservationDto;
      reader.readMessage(value,proto.protoapi.ReservationDto.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    case 4:
      var value = new proto.protoapi.CustomerDto;
      reader.readMessage(value,proto.protoapi.CustomerDto.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 5:
      var value = new proto.protoapi.PaymentDto;
      reader.readMessage(value,proto.protoapi.PaymentDto.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedby(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedby(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 12:
      var value = new proto.protoapi.OrdAddDto;
      reader.readMessage(value,proto.protoapi.OrdAddDto.deserializeBinaryFromReader);
      msg.addAdditional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.ReportDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.ReportDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.ReportDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReportDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protoapi.ProductDto.serializeBinaryToWriter
    );
  }
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protoapi.ReservationDto.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protoapi.CustomerDto.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protoapi.PaymentDto.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAdditionalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.protoapi.OrdAddDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protoapi.ReportDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProductDto product = 2;
 * @return {?proto.protoapi.ProductDto}
 */
proto.protoapi.ReportDto.prototype.getProduct = function() {
  return /** @type{?proto.protoapi.ProductDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.ProductDto, 2));
};


/**
 * @param {?proto.protoapi.ProductDto|undefined} value
 * @return {!proto.protoapi.ReportDto} returns this
*/
proto.protoapi.ReportDto.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReportDto.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReservationDto reservation = 3;
 * @return {?proto.protoapi.ReservationDto}
 */
proto.protoapi.ReportDto.prototype.getReservation = function() {
  return /** @type{?proto.protoapi.ReservationDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.ReservationDto, 3));
};


/**
 * @param {?proto.protoapi.ReservationDto|undefined} value
 * @return {!proto.protoapi.ReportDto} returns this
*/
proto.protoapi.ReportDto.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReportDto.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CustomerDto customer = 4;
 * @return {?proto.protoapi.CustomerDto}
 */
proto.protoapi.ReportDto.prototype.getCustomer = function() {
  return /** @type{?proto.protoapi.CustomerDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.CustomerDto, 4));
};


/**
 * @param {?proto.protoapi.CustomerDto|undefined} value
 * @return {!proto.protoapi.ReportDto} returns this
*/
proto.protoapi.ReportDto.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReportDto.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PaymentDto payment = 5;
 * @return {?proto.protoapi.PaymentDto}
 */
proto.protoapi.ReportDto.prototype.getPayment = function() {
  return /** @type{?proto.protoapi.PaymentDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.PaymentDto, 5));
};


/**
 * @param {?proto.protoapi.PaymentDto|undefined} value
 * @return {!proto.protoapi.ReportDto} returns this
*/
proto.protoapi.ReportDto.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReportDto.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.protoapi.ReportDto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.ReportDto.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.ReportDto} returns this
*/
proto.protoapi.ReportDto.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReportDto.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.ReportDto.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.ReportDto} returns this
*/
proto.protoapi.ReportDto.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReportDto.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string createdBy = 9;
 * @return {string}
 */
proto.protoapi.ReportDto.prototype.getCreatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.setCreatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string updatedBy = 10;
 * @return {string}
 */
proto.protoapi.ReportDto.prototype.getUpdatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.setUpdatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string orderNumber = 11;
 * @return {string}
 */
proto.protoapi.ReportDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated OrdAddDto additional = 12;
 * @return {!Array<!proto.protoapi.OrdAddDto>}
 */
proto.protoapi.ReportDto.prototype.getAdditionalList = function() {
  return /** @type{!Array<!proto.protoapi.OrdAddDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.OrdAddDto, 12));
};


/**
 * @param {!Array<!proto.protoapi.OrdAddDto>} value
 * @return {!proto.protoapi.ReportDto} returns this
*/
proto.protoapi.ReportDto.prototype.setAdditionalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.protoapi.OrdAddDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.ReportDto.prototype.addAdditional = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.protoapi.OrdAddDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.ReportDto} returns this
 */
proto.protoapi.ReportDto.prototype.clearAdditionalList = function() {
  return this.setAdditionalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.ProductDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.ProductDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.ProductDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ProductDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.ProductDto}
 */
proto.protoapi.ProductDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.ProductDto;
  return proto.protoapi.ProductDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.ProductDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.ProductDto}
 */
proto.protoapi.ProductDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.ProductDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.ProductDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.ProductDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ProductDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protoapi.ProductDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ProductDto} returns this
 */
proto.protoapi.ProductDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protoapi.ProductDto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ProductDto} returns this
 */
proto.protoapi.ProductDto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.protoapi.ProductDto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ProductDto} returns this
 */
proto.protoapi.ProductDto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 price = 4;
 * @return {number}
 */
proto.protoapi.ProductDto.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.ProductDto} returns this
 */
proto.protoapi.ProductDto.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.ReservationDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.ReservationDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.ReservationDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReservationDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    product: (f = msg.getProduct()) && proto.protoapi.ProductDto.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderdate: (f = msg.getOrderdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reportid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customer: (f = msg.getCustomer()) && proto.protoapi.CustomerDto.toObject(includeInstance, f),
    ordersequence: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.ReservationDto}
 */
proto.protoapi.ReservationDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.ReservationDto;
  return proto.protoapi.ReservationDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.ReservationDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.ReservationDto}
 */
proto.protoapi.ReservationDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 3:
      var value = new proto.protoapi.ProductDto;
      reader.readMessage(value,proto.protoapi.ProductDto.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 8:
      var value = new proto.protoapi.CustomerDto;
      reader.readMessage(value,proto.protoapi.CustomerDto.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrdersequence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.ReservationDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.ReservationDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.ReservationDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReservationDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protoapi.ProductDto.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protoapi.CustomerDto.serializeBinaryToWriter
    );
  }
  f = message.getOrdersequence();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.ReservationDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.ReservationDto} returns this
*/
proto.protoapi.ReservationDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReservationDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.ReservationDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.ReservationDto} returns this
*/
proto.protoapi.ReservationDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReservationDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductDto product = 3;
 * @return {?proto.protoapi.ProductDto}
 */
proto.protoapi.ReservationDto.prototype.getProduct = function() {
  return /** @type{?proto.protoapi.ProductDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.ProductDto, 3));
};


/**
 * @param {?proto.protoapi.ProductDto|undefined} value
 * @return {!proto.protoapi.ReservationDto} returns this
*/
proto.protoapi.ReservationDto.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReservationDto.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.protoapi.ReservationDto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp orderDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.ReservationDto.prototype.getOrderdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.ReservationDto} returns this
*/
proto.protoapi.ReservationDto.prototype.setOrderdate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.clearOrderdate = function() {
  return this.setOrderdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReservationDto.prototype.hasOrderdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string reportId = 6;
 * @return {string}
 */
proto.protoapi.ReservationDto.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string orderNumber = 7;
 * @return {string}
 */
proto.protoapi.ReservationDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional CustomerDto customer = 8;
 * @return {?proto.protoapi.CustomerDto}
 */
proto.protoapi.ReservationDto.prototype.getCustomer = function() {
  return /** @type{?proto.protoapi.CustomerDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.CustomerDto, 8));
};


/**
 * @param {?proto.protoapi.CustomerDto|undefined} value
 * @return {!proto.protoapi.ReservationDto} returns this
*/
proto.protoapi.ReservationDto.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ReservationDto.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 orderSequence = 9;
 * @return {number}
 */
proto.protoapi.ReservationDto.prototype.getOrdersequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.ReservationDto} returns this
 */
proto.protoapi.ReservationDto.prototype.setOrdersequence = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.CustomerDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.CustomerDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.CustomerDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CustomerDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    company: jspb.Message.getFieldWithDefault(msg, 3, ""),
    checkin: (f = msg.getCheckin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    checkout: (f = msg.getCheckout()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.CustomerDto}
 */
proto.protoapi.CustomerDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.CustomerDto;
  return proto.protoapi.CustomerDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.CustomerDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.CustomerDto}
 */
proto.protoapi.CustomerDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCheckin(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCheckout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.CustomerDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.CustomerDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.CustomerDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CustomerDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCheckin();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCheckout();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.protoapi.CustomerDto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.CustomerDto} returns this
 */
proto.protoapi.CustomerDto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.protoapi.CustomerDto.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.CustomerDto} returns this
 */
proto.protoapi.CustomerDto.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company = 3;
 * @return {string}
 */
proto.protoapi.CustomerDto.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.CustomerDto} returns this
 */
proto.protoapi.CustomerDto.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp checkIn = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.CustomerDto.prototype.getCheckin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.CustomerDto} returns this
*/
proto.protoapi.CustomerDto.prototype.setCheckin = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.CustomerDto} returns this
 */
proto.protoapi.CustomerDto.prototype.clearCheckin = function() {
  return this.setCheckin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.CustomerDto.prototype.hasCheckin = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp checkOut = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.CustomerDto.prototype.getCheckout = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.CustomerDto} returns this
*/
proto.protoapi.CustomerDto.prototype.setCheckout = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.CustomerDto} returns this
 */
proto.protoapi.CustomerDto.prototype.clearCheckout = function() {
  return this.setCheckout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.CustomerDto.prototype.hasCheckout = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.PaymentDto.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.PaymentDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.PaymentDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.PaymentDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.PaymentDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    downpay: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalpay: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    discamount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    discpercent: jspb.Message.getFieldWithDefault(msg, 6, 0),
    taxList: jspb.Message.toObjectList(msg.getTaxList(),
    proto.protoapi.CreateTaxReqDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.PaymentDto}
 */
proto.protoapi.PaymentDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.PaymentDto;
  return proto.protoapi.PaymentDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.PaymentDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.PaymentDto}
 */
proto.protoapi.PaymentDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDownpay(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalpay(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscamount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscpercent(value);
      break;
    case 7:
      var value = new proto.protoapi.CreateTaxReqDto;
      reader.readMessage(value,proto.protoapi.CreateTaxReqDto.deserializeBinaryFromReader);
      msg.addTax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.PaymentDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.PaymentDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.PaymentDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.PaymentDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDownpay();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTotalpay();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDiscamount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDiscpercent();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTaxList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.protoapi.CreateTaxReqDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 downPay = 1;
 * @return {number}
 */
proto.protoapi.PaymentDto.prototype.getDownpay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.PaymentDto} returns this
 */
proto.protoapi.PaymentDto.prototype.setDownpay = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 totalPay = 2;
 * @return {number}
 */
proto.protoapi.PaymentDto.prototype.getTotalpay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.PaymentDto} returns this
 */
proto.protoapi.PaymentDto.prototype.setTotalpay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.protoapi.PaymentDto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.PaymentDto} returns this
 */
proto.protoapi.PaymentDto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.protoapi.PaymentDto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.PaymentDto} returns this
 */
proto.protoapi.PaymentDto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 discAmount = 5;
 * @return {number}
 */
proto.protoapi.PaymentDto.prototype.getDiscamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.PaymentDto} returns this
 */
proto.protoapi.PaymentDto.prototype.setDiscamount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 discPercent = 6;
 * @return {number}
 */
proto.protoapi.PaymentDto.prototype.getDiscpercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.PaymentDto} returns this
 */
proto.protoapi.PaymentDto.prototype.setDiscpercent = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated CreateTaxReqDto tax = 7;
 * @return {!Array<!proto.protoapi.CreateTaxReqDto>}
 */
proto.protoapi.PaymentDto.prototype.getTaxList = function() {
  return /** @type{!Array<!proto.protoapi.CreateTaxReqDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.CreateTaxReqDto, 7));
};


/**
 * @param {!Array<!proto.protoapi.CreateTaxReqDto>} value
 * @return {!proto.protoapi.PaymentDto} returns this
*/
proto.protoapi.PaymentDto.prototype.setTaxList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.protoapi.CreateTaxReqDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.CreateTaxReqDto}
 */
proto.protoapi.PaymentDto.prototype.addTax = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.protoapi.CreateTaxReqDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.PaymentDto} returns this
 */
proto.protoapi.PaymentDto.prototype.clearTaxList = function() {
  return this.setTaxList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.BookingDto.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.BookingDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.BookingDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.BookingDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.BookingDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    customer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerphone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customercompany: jspb.Message.getFieldWithDefault(msg, 6, ""),
    payment: (f = msg.getPayment()) && proto.protoapi.PaymentDto.toObject(includeInstance, f),
    additionalList: jspb.Message.toObjectList(msg.getAdditionalList(),
    proto.protoapi.OrdAddDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.BookingDto}
 */
proto.protoapi.BookingDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.BookingDto;
  return proto.protoapi.BookingDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.BookingDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.BookingDto}
 */
proto.protoapi.BookingDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerphone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomercompany(value);
      break;
    case 7:
      var value = new proto.protoapi.PaymentDto;
      reader.readMessage(value,proto.protoapi.PaymentDto.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 8:
      var value = new proto.protoapi.OrdAddDto;
      reader.readMessage(value,proto.protoapi.OrdAddDto.deserializeBinaryFromReader);
      msg.addAdditional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.BookingDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.BookingDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.BookingDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.BookingDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerphone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomercompany();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protoapi.PaymentDto.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.protoapi.OrdAddDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string productId = 1;
 * @return {string}
 */
proto.protoapi.BookingDto.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.BookingDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.BookingDto} returns this
*/
proto.protoapi.BookingDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.BookingDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.BookingDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.BookingDto} returns this
*/
proto.protoapi.BookingDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.BookingDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string customer = 4;
 * @return {string}
 */
proto.protoapi.BookingDto.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customerPhone = 5;
 * @return {string}
 */
proto.protoapi.BookingDto.prototype.getCustomerphone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.setCustomerphone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customerCompany = 6;
 * @return {string}
 */
proto.protoapi.BookingDto.prototype.getCustomercompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.setCustomercompany = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PaymentDto payment = 7;
 * @return {?proto.protoapi.PaymentDto}
 */
proto.protoapi.BookingDto.prototype.getPayment = function() {
  return /** @type{?proto.protoapi.PaymentDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.PaymentDto, 7));
};


/**
 * @param {?proto.protoapi.PaymentDto|undefined} value
 * @return {!proto.protoapi.BookingDto} returns this
*/
proto.protoapi.BookingDto.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.BookingDto.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated OrdAddDto additional = 8;
 * @return {!Array<!proto.protoapi.OrdAddDto>}
 */
proto.protoapi.BookingDto.prototype.getAdditionalList = function() {
  return /** @type{!Array<!proto.protoapi.OrdAddDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.OrdAddDto, 8));
};


/**
 * @param {!Array<!proto.protoapi.OrdAddDto>} value
 * @return {!proto.protoapi.BookingDto} returns this
*/
proto.protoapi.BookingDto.prototype.setAdditionalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.protoapi.OrdAddDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.BookingDto.prototype.addAdditional = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.protoapi.OrdAddDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.BookingDto} returns this
 */
proto.protoapi.BookingDto.prototype.clearAdditionalList = function() {
  return this.setAdditionalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.CheckInDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.CheckInDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.CheckInDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CheckInDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    indate: (f = msg.getIndate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    product: (f = msg.getProduct()) && proto.protoapi.ProductDto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.CheckInDto}
 */
proto.protoapi.CheckInDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.CheckInDto;
  return proto.protoapi.CheckInDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.CheckInDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.CheckInDto}
 */
proto.protoapi.CheckInDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIndate(value);
      break;
    case 3:
      var value = new proto.protoapi.ProductDto;
      reader.readMessage(value,proto.protoapi.ProductDto.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.CheckInDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.CheckInDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.CheckInDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CheckInDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protoapi.ProductDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.protoapi.CheckInDto.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.CheckInDto} returns this
 */
proto.protoapi.CheckInDto.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp inDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.CheckInDto.prototype.getIndate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.CheckInDto} returns this
*/
proto.protoapi.CheckInDto.prototype.setIndate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.CheckInDto} returns this
 */
proto.protoapi.CheckInDto.prototype.clearIndate = function() {
  return this.setIndate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.CheckInDto.prototype.hasIndate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductDto product = 3;
 * @return {?proto.protoapi.ProductDto}
 */
proto.protoapi.CheckInDto.prototype.getProduct = function() {
  return /** @type{?proto.protoapi.ProductDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.ProductDto, 3));
};


/**
 * @param {?proto.protoapi.ProductDto|undefined} value
 * @return {!proto.protoapi.CheckInDto} returns this
*/
proto.protoapi.CheckInDto.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.CheckInDto} returns this
 */
proto.protoapi.CheckInDto.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.CheckInDto.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.CheckOutDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.CheckOutDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.CheckOutDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CheckOutDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outdate: (f = msg.getOutdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.CheckOutDto}
 */
proto.protoapi.CheckOutDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.CheckOutDto;
  return proto.protoapi.CheckOutDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.CheckOutDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.CheckOutDto}
 */
proto.protoapi.CheckOutDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOutdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.CheckOutDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.CheckOutDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.CheckOutDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CheckOutDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.protoapi.CheckOutDto.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.CheckOutDto} returns this
 */
proto.protoapi.CheckOutDto.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp outDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.CheckOutDto.prototype.getOutdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.CheckOutDto} returns this
*/
proto.protoapi.CheckOutDto.prototype.setOutdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.CheckOutDto} returns this
 */
proto.protoapi.CheckOutDto.prototype.clearOutdate = function() {
  return this.setOutdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.CheckOutDto.prototype.hasOutdate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.ReportDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.ReportDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.ReportDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.ReportDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReportDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.ReportDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.ReportDtoList}
 */
proto.protoapi.ReportDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.ReportDtoList;
  return proto.protoapi.ReportDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.ReportDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.ReportDtoList}
 */
proto.protoapi.ReportDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.ReportDto;
      reader.readMessage(value,proto.protoapi.ReportDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.ReportDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.ReportDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.ReportDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReportDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.ReportDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReportDto list = 1;
 * @return {!Array<!proto.protoapi.ReportDto>}
 */
proto.protoapi.ReportDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.ReportDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.ReportDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.ReportDto>} value
 * @return {!proto.protoapi.ReportDtoList} returns this
*/
proto.protoapi.ReportDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.ReportDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.ReportDto}
 */
proto.protoapi.ReportDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.ReportDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.ReportDtoList} returns this
 */
proto.protoapi.ReportDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.ReservationDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.ReservationDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.ReservationDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.ReservationDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReservationDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.ReservationDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.ReservationDtoList}
 */
proto.protoapi.ReservationDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.ReservationDtoList;
  return proto.protoapi.ReservationDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.ReservationDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.ReservationDtoList}
 */
proto.protoapi.ReservationDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.ReservationDto;
      reader.readMessage(value,proto.protoapi.ReservationDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.ReservationDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.ReservationDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.ReservationDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ReservationDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.ReservationDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReservationDto list = 1;
 * @return {!Array<!proto.protoapi.ReservationDto>}
 */
proto.protoapi.ReservationDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.ReservationDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.ReservationDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.ReservationDto>} value
 * @return {!proto.protoapi.ReservationDtoList} returns this
*/
proto.protoapi.ReservationDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.ReservationDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.ReservationDto}
 */
proto.protoapi.ReservationDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.ReservationDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.ReservationDtoList} returns this
 */
proto.protoapi.ReservationDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.CheckInDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.CheckInDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.CheckInDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.CheckInDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CheckInDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.CheckInDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.CheckInDtoList}
 */
proto.protoapi.CheckInDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.CheckInDtoList;
  return proto.protoapi.CheckInDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.CheckInDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.CheckInDtoList}
 */
proto.protoapi.CheckInDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.CheckInDto;
      reader.readMessage(value,proto.protoapi.CheckInDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.CheckInDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.CheckInDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.CheckInDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.CheckInDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.CheckInDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CheckInDto list = 1;
 * @return {!Array<!proto.protoapi.CheckInDto>}
 */
proto.protoapi.CheckInDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.CheckInDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.CheckInDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.CheckInDto>} value
 * @return {!proto.protoapi.CheckInDtoList} returns this
*/
proto.protoapi.CheckInDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.CheckInDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.CheckInDto}
 */
proto.protoapi.CheckInDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.CheckInDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.CheckInDtoList} returns this
 */
proto.protoapi.CheckInDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.OrdAddDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.OrdAddDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.OrdAddDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.OrdAddDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deliv: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.OrdAddDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.OrdAddDto;
  return proto.protoapi.OrdAddDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.OrdAddDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.OrdAddDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeliv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.OrdAddDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.OrdAddDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.OrdAddDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.OrdAddDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeliv();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.protoapi.OrdAddDto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.OrdAddDto} returns this
 */
proto.protoapi.OrdAddDto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 price = 2;
 * @return {number}
 */
proto.protoapi.OrdAddDto.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.OrdAddDto} returns this
 */
proto.protoapi.OrdAddDto.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.protoapi.OrdAddDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.OrdAddDto} returns this
 */
proto.protoapi.OrdAddDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool deliv = 4;
 * @return {boolean}
 */
proto.protoapi.OrdAddDto.prototype.getDeliv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protoapi.OrdAddDto} returns this
 */
proto.protoapi.OrdAddDto.prototype.setDeliv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.OrdAddDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.OrdAddDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.OrdAddDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.OrdAddDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.OrdAddDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.OrdAddDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.OrdAddDtoList}
 */
proto.protoapi.OrdAddDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.OrdAddDtoList;
  return proto.protoapi.OrdAddDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.OrdAddDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.OrdAddDtoList}
 */
proto.protoapi.OrdAddDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.OrdAddDto;
      reader.readMessage(value,proto.protoapi.OrdAddDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.OrdAddDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.OrdAddDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.OrdAddDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.OrdAddDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.OrdAddDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrdAddDto list = 1;
 * @return {!Array<!proto.protoapi.OrdAddDto>}
 */
proto.protoapi.OrdAddDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.OrdAddDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.OrdAddDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.OrdAddDto>} value
 * @return {!proto.protoapi.OrdAddDtoList} returns this
*/
proto.protoapi.OrdAddDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.OrdAddDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.OrdAddDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.OrdAddDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.OrdAddDtoList} returns this
 */
proto.protoapi.OrdAddDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GetDateLRsvDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GetDateLRsvDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GetDateLRsvDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GetDateLRsvDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GetDateLRsvDto}
 */
proto.protoapi.GetDateLRsvDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GetDateLRsvDto;
  return proto.protoapi.GetDateLRsvDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GetDateLRsvDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GetDateLRsvDto}
 */
proto.protoapi.GetDateLRsvDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GetDateLRsvDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GetDateLRsvDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GetDateLRsvDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GetDateLRsvDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GetDateLRsvDto.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GetDateLRsvDto} returns this
*/
proto.protoapi.GetDateLRsvDto.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GetDateLRsvDto} returns this
 */
proto.protoapi.GetDateLRsvDto.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GetDateLRsvDto.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.GroupBookingDto.repeatedFields_ = [1,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GroupBookingDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GroupBookingDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GroupBookingDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GroupBookingDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    productidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    customer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerphone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customercompany: jspb.Message.getFieldWithDefault(msg, 6, ""),
    payment: (f = msg.getPayment()) && proto.protoapi.PaymentDto.toObject(includeInstance, f),
    additionalList: jspb.Message.toObjectList(msg.getAdditionalList(),
    proto.protoapi.OrdAddDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GroupBookingDto}
 */
proto.protoapi.GroupBookingDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GroupBookingDto;
  return proto.protoapi.GroupBookingDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GroupBookingDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GroupBookingDto}
 */
proto.protoapi.GroupBookingDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerphone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomercompany(value);
      break;
    case 7:
      var value = new proto.protoapi.PaymentDto;
      reader.readMessage(value,proto.protoapi.PaymentDto.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 8:
      var value = new proto.protoapi.OrdAddDto;
      reader.readMessage(value,proto.protoapi.OrdAddDto.deserializeBinaryFromReader);
      msg.addAdditional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GroupBookingDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GroupBookingDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GroupBookingDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GroupBookingDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerphone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomercompany();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protoapi.PaymentDto.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.protoapi.OrdAddDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string productId = 1;
 * @return {!Array<string>}
 */
proto.protoapi.GroupBookingDto.prototype.getProductidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.setProductidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.addProductid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.clearProductidList = function() {
  return this.setProductidList([]);
};


/**
 * optional google.protobuf.Timestamp startDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GroupBookingDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
*/
proto.protoapi.GroupBookingDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GroupBookingDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GroupBookingDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
*/
proto.protoapi.GroupBookingDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GroupBookingDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string customer = 4;
 * @return {string}
 */
proto.protoapi.GroupBookingDto.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customerPhone = 5;
 * @return {string}
 */
proto.protoapi.GroupBookingDto.prototype.getCustomerphone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.setCustomerphone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customerCompany = 6;
 * @return {string}
 */
proto.protoapi.GroupBookingDto.prototype.getCustomercompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.setCustomercompany = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PaymentDto payment = 7;
 * @return {?proto.protoapi.PaymentDto}
 */
proto.protoapi.GroupBookingDto.prototype.getPayment = function() {
  return /** @type{?proto.protoapi.PaymentDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.PaymentDto, 7));
};


/**
 * @param {?proto.protoapi.PaymentDto|undefined} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
*/
proto.protoapi.GroupBookingDto.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GroupBookingDto.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated OrdAddDto additional = 8;
 * @return {!Array<!proto.protoapi.OrdAddDto>}
 */
proto.protoapi.GroupBookingDto.prototype.getAdditionalList = function() {
  return /** @type{!Array<!proto.protoapi.OrdAddDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.OrdAddDto, 8));
};


/**
 * @param {!Array<!proto.protoapi.OrdAddDto>} value
 * @return {!proto.protoapi.GroupBookingDto} returns this
*/
proto.protoapi.GroupBookingDto.prototype.setAdditionalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.protoapi.OrdAddDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.GroupBookingDto.prototype.addAdditional = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.protoapi.OrdAddDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.GroupBookingDto} returns this
 */
proto.protoapi.GroupBookingDto.prototype.clearAdditionalList = function() {
  return this.setAdditionalList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.GroupReportDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GroupReportDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GroupReportDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GroupReportDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GroupReportDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupbookingList: jspb.Message.toObjectList(msg.getGroupbookingList(),
    proto.protoapi.ReportDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GroupReportDtoList}
 */
proto.protoapi.GroupReportDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GroupReportDtoList;
  return proto.protoapi.GroupReportDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GroupReportDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GroupReportDtoList}
 */
proto.protoapi.GroupReportDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.ReportDto;
      reader.readMessage(value,proto.protoapi.ReportDto.deserializeBinaryFromReader);
      msg.addGroupbooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GroupReportDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GroupReportDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GroupReportDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GroupReportDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupbookingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.ReportDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReportDto groupBooking = 1;
 * @return {!Array<!proto.protoapi.ReportDto>}
 */
proto.protoapi.GroupReportDtoList.prototype.getGroupbookingList = function() {
  return /** @type{!Array<!proto.protoapi.ReportDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.ReportDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.ReportDto>} value
 * @return {!proto.protoapi.GroupReportDtoList} returns this
*/
proto.protoapi.GroupReportDtoList.prototype.setGroupbookingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.ReportDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.ReportDto}
 */
proto.protoapi.GroupReportDtoList.prototype.addGroupbooking = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.ReportDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.GroupReportDtoList} returns this
 */
proto.protoapi.GroupReportDtoList.prototype.clearGroupbookingList = function() {
  return this.setGroupbookingList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.DetailBookingDto.repeatedFields_ = [2,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DetailBookingDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DetailBookingDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DetailBookingDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DetailBookingDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productList: jspb.Message.toObjectList(msg.getProductList(),
    proto.protoapi.ProductDto.toObject, includeInstance),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    customer: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerphone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    customercompany: jspb.Message.getFieldWithDefault(msg, 7, ""),
    payment: (f = msg.getPayment()) && proto.protoapi.PaymentDto.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    additionalList: jspb.Message.toObjectList(msg.getAdditionalList(),
    proto.protoapi.OrdAddDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DetailBookingDto}
 */
proto.protoapi.DetailBookingDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DetailBookingDto;
  return proto.protoapi.DetailBookingDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DetailBookingDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DetailBookingDto}
 */
proto.protoapi.DetailBookingDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 2:
      var value = new proto.protoapi.ProductDto;
      reader.readMessage(value,proto.protoapi.ProductDto.deserializeBinaryFromReader);
      msg.addProduct(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerphone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomercompany(value);
      break;
    case 8:
      var value = new proto.protoapi.PaymentDto;
      reader.readMessage(value,proto.protoapi.PaymentDto.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = new proto.protoapi.OrdAddDto;
      reader.readMessage(value,proto.protoapi.OrdAddDto.deserializeBinaryFromReader);
      msg.addAdditional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DetailBookingDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DetailBookingDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DetailBookingDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DetailBookingDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.protoapi.ProductDto.serializeBinaryToWriter
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerphone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustomercompany();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protoapi.PaymentDto.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAdditionalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.protoapi.OrdAddDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.protoapi.DetailBookingDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ProductDto product = 2;
 * @return {!Array<!proto.protoapi.ProductDto>}
 */
proto.protoapi.DetailBookingDto.prototype.getProductList = function() {
  return /** @type{!Array<!proto.protoapi.ProductDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.ProductDto, 2));
};


/**
 * @param {!Array<!proto.protoapi.ProductDto>} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
*/
proto.protoapi.DetailBookingDto.prototype.setProductList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.protoapi.ProductDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.ProductDto}
 */
proto.protoapi.DetailBookingDto.prototype.addProduct = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.protoapi.ProductDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.clearProductList = function() {
  return this.setProductList([]);
};


/**
 * optional google.protobuf.Timestamp startDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.DetailBookingDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
*/
proto.protoapi.DetailBookingDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.DetailBookingDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.DetailBookingDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
*/
proto.protoapi.DetailBookingDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.DetailBookingDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string customer = 5;
 * @return {string}
 */
proto.protoapi.DetailBookingDto.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customerPhone = 6;
 * @return {string}
 */
proto.protoapi.DetailBookingDto.prototype.getCustomerphone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.setCustomerphone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string customerCompany = 7;
 * @return {string}
 */
proto.protoapi.DetailBookingDto.prototype.getCustomercompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.setCustomercompany = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional PaymentDto payment = 8;
 * @return {?proto.protoapi.PaymentDto}
 */
proto.protoapi.DetailBookingDto.prototype.getPayment = function() {
  return /** @type{?proto.protoapi.PaymentDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.PaymentDto, 8));
};


/**
 * @param {?proto.protoapi.PaymentDto|undefined} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
*/
proto.protoapi.DetailBookingDto.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.DetailBookingDto.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.protoapi.DetailBookingDto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated OrdAddDto additional = 10;
 * @return {!Array<!proto.protoapi.OrdAddDto>}
 */
proto.protoapi.DetailBookingDto.prototype.getAdditionalList = function() {
  return /** @type{!Array<!proto.protoapi.OrdAddDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.OrdAddDto, 10));
};


/**
 * @param {!Array<!proto.protoapi.OrdAddDto>} value
 * @return {!proto.protoapi.DetailBookingDto} returns this
*/
proto.protoapi.DetailBookingDto.prototype.setAdditionalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.protoapi.OrdAddDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.DetailBookingDto.prototype.addAdditional = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.protoapi.OrdAddDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.DetailBookingDto} returns this
 */
proto.protoapi.DetailBookingDto.prototype.clearAdditionalList = function() {
  return this.setAdditionalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.ChecklistDelivReqDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.ChecklistDelivReqDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.ChecklistDelivReqDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ChecklistDelivReqDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deliv: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.ChecklistDelivReqDto}
 */
proto.protoapi.ChecklistDelivReqDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.ChecklistDelivReqDto;
  return proto.protoapi.ChecklistDelivReqDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.ChecklistDelivReqDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.ChecklistDelivReqDto}
 */
proto.protoapi.ChecklistDelivReqDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeliv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.ChecklistDelivReqDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.ChecklistDelivReqDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.ChecklistDelivReqDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ChecklistDelivReqDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeliv();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protoapi.ChecklistDelivReqDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ChecklistDelivReqDto} returns this
 */
proto.protoapi.ChecklistDelivReqDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool deliv = 2;
 * @return {boolean}
 */
proto.protoapi.ChecklistDelivReqDto.prototype.getDeliv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protoapi.ChecklistDelivReqDto} returns this
 */
proto.protoapi.ChecklistDelivReqDto.prototype.setDeliv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.LunasResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.LunasResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.LunasResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.LunasResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.LunasResDto}
 */
proto.protoapi.LunasResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.LunasResDto;
  return proto.protoapi.LunasResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.LunasResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.LunasResDto}
 */
proto.protoapi.LunasResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.LunasResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.LunasResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.LunasResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.LunasResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.protoapi.LunasResDto.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.LunasResDto} returns this
 */
proto.protoapi.LunasResDto.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.EditBookingReqDto.repeatedFields_ = [1,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.EditBookingReqDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.EditBookingReqDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.EditBookingReqDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.EditBookingReqDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    productidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    customer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerphone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customercompany: jspb.Message.getFieldWithDefault(msg, 6, ""),
    payment: (f = msg.getPayment()) && proto.protoapi.PaymentDto.toObject(includeInstance, f),
    additionalList: jspb.Message.toObjectList(msg.getAdditionalList(),
    proto.protoapi.OrdAddDto.toObject, includeInstance),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.EditBookingReqDto}
 */
proto.protoapi.EditBookingReqDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.EditBookingReqDto;
  return proto.protoapi.EditBookingReqDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.EditBookingReqDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.EditBookingReqDto}
 */
proto.protoapi.EditBookingReqDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerphone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomercompany(value);
      break;
    case 7:
      var value = new proto.protoapi.PaymentDto;
      reader.readMessage(value,proto.protoapi.PaymentDto.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 8:
      var value = new proto.protoapi.OrdAddDto;
      reader.readMessage(value,proto.protoapi.OrdAddDto.deserializeBinaryFromReader);
      msg.addAdditional(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.EditBookingReqDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.EditBookingReqDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.EditBookingReqDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.EditBookingReqDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerphone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomercompany();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protoapi.PaymentDto.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.protoapi.OrdAddDto.serializeBinaryToWriter
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * repeated string productId = 1;
 * @return {!Array<string>}
 */
proto.protoapi.EditBookingReqDto.prototype.getProductidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.setProductidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.addProductid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.clearProductidList = function() {
  return this.setProductidList([]);
};


/**
 * optional google.protobuf.Timestamp startDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.EditBookingReqDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
*/
proto.protoapi.EditBookingReqDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.EditBookingReqDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.EditBookingReqDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
*/
proto.protoapi.EditBookingReqDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.EditBookingReqDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string customer = 4;
 * @return {string}
 */
proto.protoapi.EditBookingReqDto.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customerPhone = 5;
 * @return {string}
 */
proto.protoapi.EditBookingReqDto.prototype.getCustomerphone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.setCustomerphone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customerCompany = 6;
 * @return {string}
 */
proto.protoapi.EditBookingReqDto.prototype.getCustomercompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.setCustomercompany = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PaymentDto payment = 7;
 * @return {?proto.protoapi.PaymentDto}
 */
proto.protoapi.EditBookingReqDto.prototype.getPayment = function() {
  return /** @type{?proto.protoapi.PaymentDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.PaymentDto, 7));
};


/**
 * @param {?proto.protoapi.PaymentDto|undefined} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
*/
proto.protoapi.EditBookingReqDto.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.EditBookingReqDto.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated OrdAddDto additional = 8;
 * @return {!Array<!proto.protoapi.OrdAddDto>}
 */
proto.protoapi.EditBookingReqDto.prototype.getAdditionalList = function() {
  return /** @type{!Array<!proto.protoapi.OrdAddDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.OrdAddDto, 8));
};


/**
 * @param {!Array<!proto.protoapi.OrdAddDto>} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
*/
proto.protoapi.EditBookingReqDto.prototype.setAdditionalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.protoapi.OrdAddDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.OrdAddDto}
 */
proto.protoapi.EditBookingReqDto.prototype.addAdditional = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.protoapi.OrdAddDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.clearAdditionalList = function() {
  return this.setAdditionalList([]);
};


/**
 * optional string orderNumber = 9;
 * @return {string}
 */
proto.protoapi.EditBookingReqDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.EditBookingReqDto} returns this
 */
proto.protoapi.EditBookingReqDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.EditBookingResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.EditBookingResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.EditBookingResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.EditBookingResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.EditBookingResDto}
 */
proto.protoapi.EditBookingResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.EditBookingResDto;
  return proto.protoapi.EditBookingResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.EditBookingResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.EditBookingResDto}
 */
proto.protoapi.EditBookingResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.EditBookingResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.EditBookingResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.EditBookingResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.EditBookingResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.protoapi.EditBookingResDto.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.EditBookingResDto} returns this
 */
proto.protoapi.EditBookingResDto.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.GenerateReportDto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GenerateReportDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GenerateReportDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GenerateReportDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GenerateReportDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productList: jspb.Message.toObjectList(msg.getProductList(),
    proto.protoapi.ProductDto.toObject, includeInstance),
    payment: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startbookdate: (f = msg.getStartbookdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endbookdate: (f = msg.getEndbookdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GenerateReportDto}
 */
proto.protoapi.GenerateReportDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GenerateReportDto;
  return proto.protoapi.GenerateReportDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GenerateReportDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GenerateReportDto}
 */
proto.protoapi.GenerateReportDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 4:
      var value = new proto.protoapi.ProductDto;
      reader.readMessage(value,proto.protoapi.ProductDto.deserializeBinaryFromReader);
      msg.addProduct(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayment(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartbookdate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndbookdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GenerateReportDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GenerateReportDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GenerateReportDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GenerateReportDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.protoapi.ProductDto.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStartbookdate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndbookdate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp createdDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GenerateReportDto.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GenerateReportDto} returns this
*/
proto.protoapi.GenerateReportDto.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GenerateReportDto} returns this
 */
proto.protoapi.GenerateReportDto.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GenerateReportDto.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string orderNumber = 2;
 * @return {string}
 */
proto.protoapi.GenerateReportDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.GenerateReportDto} returns this
 */
proto.protoapi.GenerateReportDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer = 3;
 * @return {string}
 */
proto.protoapi.GenerateReportDto.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.GenerateReportDto} returns this
 */
proto.protoapi.GenerateReportDto.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProductDto product = 4;
 * @return {!Array<!proto.protoapi.ProductDto>}
 */
proto.protoapi.GenerateReportDto.prototype.getProductList = function() {
  return /** @type{!Array<!proto.protoapi.ProductDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.ProductDto, 4));
};


/**
 * @param {!Array<!proto.protoapi.ProductDto>} value
 * @return {!proto.protoapi.GenerateReportDto} returns this
*/
proto.protoapi.GenerateReportDto.prototype.setProductList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.protoapi.ProductDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.ProductDto}
 */
proto.protoapi.GenerateReportDto.prototype.addProduct = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.protoapi.ProductDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.GenerateReportDto} returns this
 */
proto.protoapi.GenerateReportDto.prototype.clearProductList = function() {
  return this.setProductList([]);
};


/**
 * optional int64 payment = 5;
 * @return {number}
 */
proto.protoapi.GenerateReportDto.prototype.getPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.GenerateReportDto} returns this
 */
proto.protoapi.GenerateReportDto.prototype.setPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp startBookDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GenerateReportDto.prototype.getStartbookdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GenerateReportDto} returns this
*/
proto.protoapi.GenerateReportDto.prototype.setStartbookdate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GenerateReportDto} returns this
 */
proto.protoapi.GenerateReportDto.prototype.clearStartbookdate = function() {
  return this.setStartbookdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GenerateReportDto.prototype.hasStartbookdate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp endBookDate = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GenerateReportDto.prototype.getEndbookdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GenerateReportDto} returns this
*/
proto.protoapi.GenerateReportDto.prototype.setEndbookdate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GenerateReportDto} returns this
 */
proto.protoapi.GenerateReportDto.prototype.clearEndbookdate = function() {
  return this.setEndbookdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GenerateReportDto.prototype.hasEndbookdate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GenerateReportReqDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GenerateReportReqDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GenerateReportReqDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GenerateReportReqDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GenerateReportReqDto}
 */
proto.protoapi.GenerateReportReqDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GenerateReportReqDto;
  return proto.protoapi.GenerateReportReqDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GenerateReportReqDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GenerateReportReqDto}
 */
proto.protoapi.GenerateReportReqDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GenerateReportReqDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GenerateReportReqDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GenerateReportReqDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GenerateReportReqDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GenerateReportReqDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GenerateReportReqDto} returns this
*/
proto.protoapi.GenerateReportReqDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GenerateReportReqDto} returns this
 */
proto.protoapi.GenerateReportReqDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GenerateReportReqDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GenerateReportReqDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GenerateReportReqDto} returns this
*/
proto.protoapi.GenerateReportReqDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GenerateReportReqDto} returns this
 */
proto.protoapi.GenerateReportReqDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GenerateReportReqDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.GenerateReportResDto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GenerateReportResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GenerateReportResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GenerateReportResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GenerateReportResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    grossinc: jspb.Message.getFieldWithDefault(msg, 3, 0),
    listreportList: jspb.Message.toObjectList(msg.getListreportList(),
    proto.protoapi.GenerateReportDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GenerateReportResDto}
 */
proto.protoapi.GenerateReportResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GenerateReportResDto;
  return proto.protoapi.GenerateReportResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GenerateReportResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GenerateReportResDto}
 */
proto.protoapi.GenerateReportResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGrossinc(value);
      break;
    case 4:
      var value = new proto.protoapi.GenerateReportDto;
      reader.readMessage(value,proto.protoapi.GenerateReportDto.deserializeBinaryFromReader);
      msg.addListreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GenerateReportResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GenerateReportResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GenerateReportResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GenerateReportResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGrossinc();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getListreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.protoapi.GenerateReportDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GenerateReportResDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GenerateReportResDto} returns this
*/
proto.protoapi.GenerateReportResDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GenerateReportResDto} returns this
 */
proto.protoapi.GenerateReportResDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GenerateReportResDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GenerateReportResDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GenerateReportResDto} returns this
*/
proto.protoapi.GenerateReportResDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GenerateReportResDto} returns this
 */
proto.protoapi.GenerateReportResDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GenerateReportResDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 grossInc = 3;
 * @return {number}
 */
proto.protoapi.GenerateReportResDto.prototype.getGrossinc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.GenerateReportResDto} returns this
 */
proto.protoapi.GenerateReportResDto.prototype.setGrossinc = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated GenerateReportDto listReport = 4;
 * @return {!Array<!proto.protoapi.GenerateReportDto>}
 */
proto.protoapi.GenerateReportResDto.prototype.getListreportList = function() {
  return /** @type{!Array<!proto.protoapi.GenerateReportDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.GenerateReportDto, 4));
};


/**
 * @param {!Array<!proto.protoapi.GenerateReportDto>} value
 * @return {!proto.protoapi.GenerateReportResDto} returns this
*/
proto.protoapi.GenerateReportResDto.prototype.setListreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.protoapi.GenerateReportDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.GenerateReportDto}
 */
proto.protoapi.GenerateReportResDto.prototype.addListreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.protoapi.GenerateReportDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.GenerateReportResDto} returns this
 */
proto.protoapi.GenerateReportResDto.prototype.clearListreportList = function() {
  return this.setListreportList([]);
};


goog.object.extend(exports, proto.protoapi);
