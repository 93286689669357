import React from 'react';
import { useHistory } from "react-router-dom";
import UserDropdown from './UserDropdown';
import ReportIcon from '../../../assets/rasamala/ReportIcon.svg';

const Header = (props: any) => {
  const { toggleMenuSidebar, username } = props;
  const history = useHistory();

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            type="button"
            onClick={() => toggleMenuSidebar()}
          >
            <i className="fas fa-bars" />
          </a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <a onClick={() => history.push("/report")} style={{ cursor: "pointer", }} className="nav-link">
          <img
            src={ReportIcon}
            alt="Report"
            height="25px"
            width="25px"
            style={{ marginTop: "-5px", marginRight: "-1rem" }}
          />
        </a>
        <UserDropdown username={username} />
      </ul>
    </nav>
  );
};

export default Header;
