import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useReport from './useReport';
import { GridColDef, DataGrid } from '@material-ui/data-grid';
import ReportPDF from './ReportPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

const flexAlignCenter = {
  display: "flex",
  alignItems: "center"
};

const headerForm = {
  display: "flex",
  alignItems: "center",
  color: "#fff",
  background: "#007bff",
  padding: "5px",
};

const Report = () => {
  const {
    startDate,
    endDate,
    monthly,
    isOpen,
    isDisabled,
    isRightDateRange,
    reportList,
    reportDto,
    setIsDisabled,
    setIsRightDateRange,
    setStartDate,
    setEndDate,
    setMonthly,
    handleShow,
    handleClose,
    formatCreateDate,
    formatBookingDate,
    monthNames,
  } = useReport();

  const [periodeLaporan, setPeriodeLaporan] = React.useState("");
  const [formatDownload, setFormatDownload] = React.useState("");

  /* Export to CSV */
  const headers = [
    { label: "Tanggal", key: "tanggal" },
    { label: "Nomor Pesanan", key: "noPesanan" },
    { label: "Nama Pelanggan", key: "namaPelanggan" },
    { label: "Penginapan", key: "penginapan" },
    { label: "Check-In", key: "checkIn" },
    { label: "Check-Out", key: "checkOut" },
    { label: "Harga Bayar", key: "hargaBayar" },
    { label: "Pemasukan", key: "pemasukan" },
  ];

  let incomeOnCsv = 0;
  const data = reportList.map((r) => { 
    incomeOnCsv += r.payment;
    return (
      {
        tanggal: formatCreateDate(r.createddate?.seconds),
        noPesanan: r.ordernumber,
        namaPelanggan: r.customer,
        penginapan: r.productList.map((prd: any) => (prd.name)),
        checkIn: formatBookingDate(r.startbookdate?.seconds),
        checkOut: formatBookingDate(r.endbookdate?.seconds),
        hargaBayar: `Rp ${r.payment.toLocaleString("id")}`,
        pemasukan: `Rp ${incomeOnCsv.toLocaleString("id")}`
      }
    )
  });

  let fileName = "";
  let datePicker = <></>;
  switch (periodeLaporan) {
    case "Laporan Harian":
      fileName = `${periodeLaporan}(${formatBookingDate(reportDto?.startdate?.seconds)}-${formatBookingDate(reportDto?.enddate?.seconds)}).${formatDownload}`;
      datePicker =
      <>
        <div className="col-lg-4">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Dari Tanggal"
              value={startDate}
              onChange={setStartDate}
              animateYearScrolling
              autoOk
              format="dd MMMM yyyy"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="col-lg-4">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Sampai Tanggal"
              value={endDate}
              minDate={new Date(startDate)}
              minDateMessage={"Tanggal harus lebih besar"}
              onError={(e) => setIsRightDateRange(e)}
              onChange={setEndDate}
              animateYearScrolling
              autoOk
              format="dd MMMM yyyy"
            />                
          </MuiPickersUtilsProvider>
        </div>
      </>
      break;
    case "Laporan Bulanan":
      fileName = `${periodeLaporan}(${monthNames(reportDto?.startdate?.seconds)}).${formatDownload}`;
      datePicker =
      <>
        <div className="col-lg-4">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Pilih Bulan & Tahun"
              views={["year", "month"]}
              value={monthly}
              minDate={new Date("2020-01-01")}
              onChange={setMonthly}
              animateYearScrolling
              autoOk
            />
          </MuiPickersUtilsProvider>
        </div>
      </>
      break;
  }

  const csvReport = {
    data: data,
    headers: headers,
    filename: fileName,
  };


  /* Table */
  const columns: GridColDef[] = [
    { headerName: "id", field: "id", hide: true },
    { headerName: "Tanggal", field: "tanggal", width: 120 },
    { headerName: "Nomor Pesanan", field: "noPesanan", width: 200 },
    { headerName: "Nama Pelanggan", field: "namaPelanggan", width: 200 },
    { headerName: "Penginapan", field: "penginapan", width: 250 },
    { headerName: "Check-In", field: "checkIn", width: 200 },
    { headerName: "Check-Out", field: "checkOut", width: 200 },
    { headerName: "Harga Bayar", field: "hargaBayar", width: 200 },
    { headerName: "Pemasukan", field: "pemasukan", width: 200 },
  ];
  
  let incomeOnTable = 0;
  const rows = reportList.map((r, index) => {
    incomeOnTable += r.payment;
    return (
      {
        id: index,
        tanggal: formatCreateDate(r.createddate?.seconds),
        noPesanan: r.ordernumber,
        namaPelanggan: r.customer,
        penginapan: r.productList.map((prd: any) => (' '+prd.name)),
        checkIn: formatBookingDate(r.startbookdate?.seconds),
        checkOut: formatBookingDate(r.endbookdate?.seconds),
        hargaBayar: `Rp ${r.payment.toLocaleString("id")}`,
        pemasukan: `Rp ${incomeOnTable.toLocaleString("id")}`
      }
    );
  });

  React.useEffect(() => {
    periodeLaporan === "" || formatDownload === "" || isRightDateRange != ""
    ? setIsDisabled(true) : setIsDisabled(false);
  }, [periodeLaporan, formatDownload, isRightDateRange]);

  let btnDownload = <></>
  switch (formatDownload) {
    case "CSV":
      btnDownload =
      <Button
        variant="warning"
        size="sm"
        disabled={isDisabled}
      >
        {isDisabled ? "Download" : (
          <CSVLink style={{ color: "#000" }} {...csvReport}>Download CSV</CSVLink>
        )}
      </Button>
      break;
    case "PDF":
      btnDownload =
      <Button
        variant="warning"
        size="sm"
        disabled={isDisabled}
      >
        {isDisabled ? "Download" : (
          <PDFDownloadLink 
            document={
              <ReportPDF
                data={data}
                startDate={formatBookingDate(reportDto?.startdate?.seconds)}
                endDate={formatBookingDate(reportDto?.enddate?.seconds)}
                grossInc={reportDto?.grossinc.toLocaleString("id")}
              />
            }
            fileName={fileName}
            style={{ color: "#000" }}
          >
            {({ loading }) => (loading ? 'Loading PDF...' : 'Download PDF')}
          </PDFDownloadLink>
        )}
      </Button>
      break;
  
    default:
      btnDownload =
      <Button
        variant="warning"
        size="sm"
        disabled={isDisabled}
      >Download</Button>;
      break;
  }

  /* For Render PDF on Browser */
  // const ViewPDF = () => (
  //   <PDFViewer
  //     height="970px"
  //     width="1908px"
  //   >
  //     <ReportPDF
  //       data={data}
  //       startDate={formatBookingDate(reportDto?.startdate?.seconds)}
  //       endDate={formatBookingDate(reportDto?.enddate?.seconds)}
  //       grossInc={reportDto?.grossinc.toLocaleString("id")}
  //     />
  //   </PDFViewer>
  // );
  // ReactDOM.render(<ViewPDF />, document.getElementById('root'));

  return (
    <div style={{ width: "99%" }}>
      <Card>
        <Card.Body>
          <div className="list-group">
            <section className="content">
              <div className="container-fluid">
                <div className="row" style={headerForm}>
                  <h6>Periode Laporan</h6>
                </div>
                <div className="row mb-5 mt-4">
                  <div className="col-lg-4" style={flexAlignCenter}>
                    <div>
                      <Form.Check
                        type="radio"
                        label="Laporan Harian"
                        value="Laporan Harian"
                        name="periodeLaporanRadios"
                        id="periodeLaporanRadios1"
                        onChange={(e: any) => setPeriodeLaporan(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Laporan Bulanan"
                        value="Laporan Bulanan"
                        name="periodeLaporanRadios"
                        id="periodeLaporanRadios2"
                        onChange={(e: any) => setPeriodeLaporan(e.target.value)}
                      />
                    </div>
                  </div>
                  {datePicker}
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row" style={headerForm}>
                  <h6>Format Download</h6>
                </div>
                <div className="row mb-3 mt-4">
                  <div className="col-lg-4">
                    <Form.Check
                      type="radio"
                      label="CSV"
                      value="CSV"
                      name="formatDownloadRadios"
                      id="formatDownloadRadios1"
                      onChange={(e: any) => setFormatDownload(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="PDF"
                      value="PDF"
                      name="formatDownloadRadios"
                      id="formatDownloadRadios2"
                      onChange={(e: any) => setFormatDownload(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <Button
                      variant="info"
                      size="sm"
                      onClick={isOpen ? handleClose : handleShow}
                      disabled={isDisabled}
                    >
                      {isOpen ? "Sembunyikan" : "Tampilkan"}
                    </Button>{' '}
                    {btnDownload}{' '}
                    <i style={{ fontSize: "12px" }}>*Periode Laporan & Format Download wajib dipilih</i>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Card.Body>
      </Card>
      {isOpen ? (
        <Card style={{ height: 450, width: '100%' }}>
          <Card.Body>
            <DataGrid 
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5,10,20]}
              disableSelectionOnClick
            />
          </Card.Body>
        </Card>
      ) : null}
    </div>
  );
}

export default Report;