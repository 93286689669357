import React from 'react';
// import { version } from '../../../../package.json';

const Footer = () => {
  const currentYear = () => {
    return new Date().getFullYear();
  }
  return (
    <footer className="main-footer">
      {/* <div className="float-right d-none d-sm-block">
        <b>Version </b>
        <span>{version}</span>
      </div> */}
      <strong>
        <span>Copyright © 2008-{currentYear()} </span>
        <a href="https://www.pondokrasamala.com" target="_blank" rel="noopener noreferrer">
          PondokRasamala.com
        </a>
        <span>.</span>
      </strong>
      <span> All rights reserved.</span>
    </footer>
  );
};

export default Footer;