import { grpc } from '@improbable-eng/grpc-web';
import { useContext, useEffect, useState } from 'react';
// import useAppModal from '../../components/Modal/useAppModal';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import { GetDateLRsvDto } from '../../protofile/report/report_pb';
import { ReportApi } from '../../protofile/report/report_pb_service';
import * as google_protobuf_timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';

export interface IBookingDetail {
  ordernumber: string;
  customer: ICustomer;
  status: string;
  startdate: IBookingDate;
  enddate: IBookingDate;
}

export interface IBookingDate {
  seconds: number;
  nanos: number;
}

export interface ICustomer {
  name: string;
  company: string;
  phone: string;
}

const initialBookingList: IBookingDetail = {
  ordernumber: '',
  customer: {
    name: '',
    company: '',
    phone: '',
  },
  status: '',
  startdate: {
    seconds: 0,
    nanos: 0,
  },
  enddate: {
    seconds: 0,
    nanos: 0,
  },
};

export const useBookingList = () => {
  const [bookingList, setBookingList] = useState<Array<IBookingDetail>>([
    initialBookingList,
  ]);
  const { showToast } = useContext(ToasterActionsContext);
  const { invokeRequest } = useContext<IServiceActions>(ServiceActionsContext);
  const sessionSelectedDate = sessionStorage.getItem("bookingListSelectedDate") !== null ? sessionStorage.getItem("bookingListSelectedDate") : new Date();
  const [selectedDate, handleDateChange] = useState<Date | any>(sessionSelectedDate);
  sessionStorage.setItem("bookingListSelectedDate", selectedDate);

  const getBookingList = async () => {
    try {
      const getDate = new google_protobuf_timestamp_pb.Timestamp();
      getDate.setSeconds(Math.round(new Date(selectedDate).getTime() / 1000));
      getDate.setNanos(0);
      const req = new GetDateLRsvDto();
      req.setDate(getDate);

      invokeRequest({
        methodDescriptor: ReportApi.ListReservation,
        request: req,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setBookingList(data.listList);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const openBookingDetail = (ordNo: string) => {
    const request = new google_protobuf_wrappers_pb.StringValue();
    request.setValue(ordNo);
    try {
      invokeRequest({
        methodDescriptor: ReportApi.DetailBooking,
        request: request,
        onStart: () => {},
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            // toggle();
          }
        },
      });
    } catch (error) {
      console.log(`Error ${error}`);
    }
  };

  const setupAsyncData = async () => {
    await getBookingList();
  };

  const useMount = () => {
    setupAsyncData();
  };

  useEffect(useMount, [selectedDate]);

  const getWhatsAppURL = (phoneNumber: string) => {
    return 'https://wa.me/' + phoneNumber + '?text=Halo';
  };

  const getMobileURL = (phoneNumber: string) => {
    return 'tel:' + phoneNumber;
  };

  const formatBookingDate = (seconds: number) => {
    let bookingdate = new Date(1970, 0, 1); // Epoch
    bookingdate.setSeconds(seconds + 25200);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let stringBookingDate =
      bookingdate.getDate() +
      '-' +
      months[bookingdate.getMonth()] +
      '-' +
      bookingdate.getFullYear();
    return stringBookingDate;
  };

  const getStatusBg = (status: string) => {
    let bg = 'bg-light';
    switch (status) {
      case 'AVAIL':
        bg = 'bg-danger';
        break;
      case 'BOOKD':
        bg = 'bg-warning';
        break;
      case 'CHKIN':
        bg = 'bg-success';
        break;
      case 'CHKOT':
        bg = 'bg-danger';
        break;
      default:
        bg = 'bg-light';
    }
    return bg;
  };

  const useBookingListValue = {
    bookingList: bookingList,
    openBookingDetail: openBookingDetail,
    getWhatsAppURL: getWhatsAppURL,
    getMobileURL: getMobileURL,
    formatBookingDate: formatBookingDate,
    getStatusBg: getStatusBg,
    selectedDate,
    handleDateChange,
  };

  return useBookingListValue;
};
