// source: room.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.protoapi.FacilityDto', null, global);
goog.exportSymbol('proto.protoapi.GetDateDto', null, global);
goog.exportSymbol('proto.protoapi.GetStartEndDateDto', null, global);
goog.exportSymbol('proto.protoapi.ListRoomsEditResDto', null, global);
goog.exportSymbol('proto.protoapi.OrderDto', null, global);
goog.exportSymbol('proto.protoapi.PaxDto', null, global);
goog.exportSymbol('proto.protoapi.RoomDto', null, global);
goog.exportSymbol('proto.protoapi.RoomDtoList', null, global);
goog.exportSymbol('proto.protoapi.UpdtPriceDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.RoomDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.RoomDto.repeatedFields_, null);
};
goog.inherits(proto.protoapi.RoomDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.RoomDto.displayName = 'proto.protoapi.RoomDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.RoomDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.RoomDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.RoomDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.RoomDtoList.displayName = 'proto.protoapi.RoomDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.PaxDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.PaxDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.PaxDto.displayName = 'proto.protoapi.PaxDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.FacilityDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.FacilityDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.FacilityDto.displayName = 'proto.protoapi.FacilityDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.UpdtPriceDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.UpdtPriceDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.UpdtPriceDto.displayName = 'proto.protoapi.UpdtPriceDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GetDateDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.GetDateDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GetDateDto.displayName = 'proto.protoapi.GetDateDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.OrderDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.OrderDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.OrderDto.displayName = 'proto.protoapi.OrderDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.GetStartEndDateDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.GetStartEndDateDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.GetStartEndDateDto.displayName = 'proto.protoapi.GetStartEndDateDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.ListRoomsEditResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.ListRoomsEditResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.ListRoomsEditResDto.displayName = 'proto.protoapi.ListRoomsEditResDto';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.RoomDto.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.RoomDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.RoomDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.RoomDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.RoomDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    no: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pax: (f = msg.getPax()) && proto.protoapi.PaxDto.toObject(includeInstance, f),
    facility: (f = msg.getFacility()) && proto.protoapi.FacilityDto.toObject(includeInstance, f),
    price: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    image: jspb.Message.getFieldWithDefault(msg, 8, ""),
    galleryList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    order: (f = msg.getOrder()) && proto.protoapi.OrderDto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.RoomDto}
 */
proto.protoapi.RoomDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.RoomDto;
  return proto.protoapi.RoomDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.RoomDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.RoomDto}
 */
proto.protoapi.RoomDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.protoapi.PaxDto;
      reader.readMessage(value,proto.protoapi.PaxDto.deserializeBinaryFromReader);
      msg.setPax(value);
      break;
    case 5:
      var value = new proto.protoapi.FacilityDto;
      reader.readMessage(value,proto.protoapi.FacilityDto.deserializeBinaryFromReader);
      msg.setFacility(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addGallery(value);
      break;
    case 10:
      var value = new proto.protoapi.OrderDto;
      reader.readMessage(value,proto.protoapi.OrderDto.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.RoomDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.RoomDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.RoomDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.RoomDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNo();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protoapi.PaxDto.serializeBinaryToWriter
    );
  }
  f = message.getFacility();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protoapi.FacilityDto.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGalleryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protoapi.OrderDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protoapi.RoomDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 no = 2;
 * @return {number}
 */
proto.protoapi.RoomDto.prototype.getNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.setNo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.protoapi.RoomDto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PaxDto pax = 4;
 * @return {?proto.protoapi.PaxDto}
 */
proto.protoapi.RoomDto.prototype.getPax = function() {
  return /** @type{?proto.protoapi.PaxDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.PaxDto, 4));
};


/**
 * @param {?proto.protoapi.PaxDto|undefined} value
 * @return {!proto.protoapi.RoomDto} returns this
*/
proto.protoapi.RoomDto.prototype.setPax = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.clearPax = function() {
  return this.setPax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.RoomDto.prototype.hasPax = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FacilityDto facility = 5;
 * @return {?proto.protoapi.FacilityDto}
 */
proto.protoapi.RoomDto.prototype.getFacility = function() {
  return /** @type{?proto.protoapi.FacilityDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.FacilityDto, 5));
};


/**
 * @param {?proto.protoapi.FacilityDto|undefined} value
 * @return {!proto.protoapi.RoomDto} returns this
*/
proto.protoapi.RoomDto.prototype.setFacility = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.clearFacility = function() {
  return this.setFacility(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.RoomDto.prototype.hasFacility = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 price = 6;
 * @return {number}
 */
proto.protoapi.RoomDto.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.protoapi.RoomDto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string image = 8;
 * @return {string}
 */
proto.protoapi.RoomDto.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string gallery = 9;
 * @return {!Array<string>}
 */
proto.protoapi.RoomDto.prototype.getGalleryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.setGalleryList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.addGallery = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.clearGalleryList = function() {
  return this.setGalleryList([]);
};


/**
 * optional OrderDto order = 10;
 * @return {?proto.protoapi.OrderDto}
 */
proto.protoapi.RoomDto.prototype.getOrder = function() {
  return /** @type{?proto.protoapi.OrderDto} */ (
    jspb.Message.getWrapperField(this, proto.protoapi.OrderDto, 10));
};


/**
 * @param {?proto.protoapi.OrderDto|undefined} value
 * @return {!proto.protoapi.RoomDto} returns this
*/
proto.protoapi.RoomDto.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.RoomDto} returns this
 */
proto.protoapi.RoomDto.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.RoomDto.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.RoomDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.RoomDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.RoomDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.RoomDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.RoomDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.RoomDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.RoomDtoList}
 */
proto.protoapi.RoomDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.RoomDtoList;
  return proto.protoapi.RoomDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.RoomDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.RoomDtoList}
 */
proto.protoapi.RoomDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.RoomDto;
      reader.readMessage(value,proto.protoapi.RoomDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.RoomDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.RoomDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.RoomDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.RoomDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.RoomDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoomDto list = 1;
 * @return {!Array<!proto.protoapi.RoomDto>}
 */
proto.protoapi.RoomDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.RoomDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.RoomDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.RoomDto>} value
 * @return {!proto.protoapi.RoomDtoList} returns this
*/
proto.protoapi.RoomDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.RoomDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.RoomDto}
 */
proto.protoapi.RoomDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.RoomDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.RoomDtoList} returns this
 */
proto.protoapi.RoomDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.PaxDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.PaxDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.PaxDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.PaxDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    normal: jspb.Message.getFieldWithDefault(msg, 1, 0),
    max: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.PaxDto}
 */
proto.protoapi.PaxDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.PaxDto;
  return proto.protoapi.PaxDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.PaxDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.PaxDto}
 */
proto.protoapi.PaxDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNormal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.PaxDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.PaxDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.PaxDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.PaxDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNormal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 normal = 1;
 * @return {number}
 */
proto.protoapi.PaxDto.prototype.getNormal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.PaxDto} returns this
 */
proto.protoapi.PaxDto.prototype.setNormal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max = 2;
 * @return {number}
 */
proto.protoapi.PaxDto.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.PaxDto} returns this
 */
proto.protoapi.PaxDto.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.FacilityDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.FacilityDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.FacilityDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.FacilityDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    bedroom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bathroom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    livingroom: jspb.Message.getFieldWithDefault(msg, 3, 0),
    kitchen: jspb.Message.getFieldWithDefault(msg, 4, 0),
    terrace: jspb.Message.getFieldWithDefault(msg, 5, 0),
    floor: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.FacilityDto}
 */
proto.protoapi.FacilityDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.FacilityDto;
  return proto.protoapi.FacilityDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.FacilityDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.FacilityDto}
 */
proto.protoapi.FacilityDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBedroom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBathroom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLivingroom(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKitchen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTerrace(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.FacilityDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.FacilityDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.FacilityDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.FacilityDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBedroom();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBathroom();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLivingroom();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getKitchen();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTerrace();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 bedroom = 1;
 * @return {number}
 */
proto.protoapi.FacilityDto.prototype.getBedroom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.FacilityDto} returns this
 */
proto.protoapi.FacilityDto.prototype.setBedroom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 bathroom = 2;
 * @return {number}
 */
proto.protoapi.FacilityDto.prototype.getBathroom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.FacilityDto} returns this
 */
proto.protoapi.FacilityDto.prototype.setBathroom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 livingroom = 3;
 * @return {number}
 */
proto.protoapi.FacilityDto.prototype.getLivingroom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.FacilityDto} returns this
 */
proto.protoapi.FacilityDto.prototype.setLivingroom = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 kitchen = 4;
 * @return {number}
 */
proto.protoapi.FacilityDto.prototype.getKitchen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.FacilityDto} returns this
 */
proto.protoapi.FacilityDto.prototype.setKitchen = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 terrace = 5;
 * @return {number}
 */
proto.protoapi.FacilityDto.prototype.getTerrace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.FacilityDto} returns this
 */
proto.protoapi.FacilityDto.prototype.setTerrace = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 floor = 6;
 * @return {number}
 */
proto.protoapi.FacilityDto.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.FacilityDto} returns this
 */
proto.protoapi.FacilityDto.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.UpdtPriceDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.UpdtPriceDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.UpdtPriceDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.UpdtPriceDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.UpdtPriceDto}
 */
proto.protoapi.UpdtPriceDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.UpdtPriceDto;
  return proto.protoapi.UpdtPriceDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.UpdtPriceDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.UpdtPriceDto}
 */
proto.protoapi.UpdtPriceDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.UpdtPriceDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.UpdtPriceDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.UpdtPriceDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.UpdtPriceDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protoapi.UpdtPriceDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.UpdtPriceDto} returns this
 */
proto.protoapi.UpdtPriceDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 price = 2;
 * @return {number}
 */
proto.protoapi.UpdtPriceDto.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.UpdtPriceDto} returns this
 */
proto.protoapi.UpdtPriceDto.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GetDateDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GetDateDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GetDateDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GetDateDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GetDateDto}
 */
proto.protoapi.GetDateDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GetDateDto;
  return proto.protoapi.GetDateDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GetDateDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GetDateDto}
 */
proto.protoapi.GetDateDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GetDateDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GetDateDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GetDateDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GetDateDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GetDateDto.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GetDateDto} returns this
*/
proto.protoapi.GetDateDto.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GetDateDto} returns this
 */
proto.protoapi.GetDateDto.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GetDateDto.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.OrderDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.OrderDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.OrderDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.OrderDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderdate: (f = msg.getOrderdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.OrderDto}
 */
proto.protoapi.OrderDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.OrderDto;
  return proto.protoapi.OrderDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.OrderDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.OrderDto}
 */
proto.protoapi.OrderDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.OrderDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.OrderDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.OrderDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.OrderDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrderstatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string productId = 1;
 * @return {string}
 */
proto.protoapi.OrderDto.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.OrderDto} returns this
 */
proto.protoapi.OrderDto.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reportId = 2;
 * @return {string}
 */
proto.protoapi.OrderDto.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.OrderDto} returns this
 */
proto.protoapi.OrderDto.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp orderDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.OrderDto.prototype.getOrderdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.OrderDto} returns this
*/
proto.protoapi.OrderDto.prototype.setOrderdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.OrderDto} returns this
 */
proto.protoapi.OrderDto.prototype.clearOrderdate = function() {
  return this.setOrderdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.OrderDto.prototype.hasOrderdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string orderStatus = 4;
 * @return {string}
 */
proto.protoapi.OrderDto.prototype.getOrderstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.OrderDto} returns this
 */
proto.protoapi.OrderDto.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.GetStartEndDateDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.GetStartEndDateDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.GetStartEndDateDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GetStartEndDateDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.GetStartEndDateDto}
 */
proto.protoapi.GetStartEndDateDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.GetStartEndDateDto;
  return proto.protoapi.GetStartEndDateDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.GetStartEndDateDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.GetStartEndDateDto}
 */
proto.protoapi.GetStartEndDateDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.GetStartEndDateDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.GetStartEndDateDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.GetStartEndDateDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.GetStartEndDateDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GetStartEndDateDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GetStartEndDateDto} returns this
*/
proto.protoapi.GetStartEndDateDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GetStartEndDateDto} returns this
 */
proto.protoapi.GetStartEndDateDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GetStartEndDateDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.GetStartEndDateDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.GetStartEndDateDto} returns this
*/
proto.protoapi.GetStartEndDateDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.GetStartEndDateDto} returns this
 */
proto.protoapi.GetStartEndDateDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.GetStartEndDateDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.ListRoomsEditResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.ListRoomsEditResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.ListRoomsEditResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ListRoomsEditResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.ListRoomsEditResDto}
 */
proto.protoapi.ListRoomsEditResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.ListRoomsEditResDto;
  return proto.protoapi.ListRoomsEditResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.ListRoomsEditResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.ListRoomsEditResDto}
 */
proto.protoapi.ListRoomsEditResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.ListRoomsEditResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.ListRoomsEditResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.ListRoomsEditResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.ListRoomsEditResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.protoapi.ListRoomsEditResDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.ListRoomsEditResDto} returns this
 */
proto.protoapi.ListRoomsEditResDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.ListRoomsEditResDto.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.ListRoomsEditResDto} returns this
*/
proto.protoapi.ListRoomsEditResDto.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ListRoomsEditResDto} returns this
 */
proto.protoapi.ListRoomsEditResDto.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ListRoomsEditResDto.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protoapi.ListRoomsEditResDto.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protoapi.ListRoomsEditResDto} returns this
*/
proto.protoapi.ListRoomsEditResDto.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protoapi.ListRoomsEditResDto} returns this
 */
proto.protoapi.ListRoomsEditResDto.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protoapi.ListRoomsEditResDto.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.protoapi);
