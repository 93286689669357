import { grpc } from '@improbable-eng/grpc-web';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import { AuthContext, IAuthContext } from '../../contexts/AuthContext';
import {
  UserAuthDto,
  UserAuthResDto,
  UserAuthResDtoList,
} from '../../protofile/auth/auth_pb';
import { AuthApi } from '../../protofile/auth/auth_pb_service';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
export interface IUser {
  username: string;
  password?: string;
  // email: string;
  mobile: string;
  role: string;
  status: string;
}

const initialUser: IUser = {
  username: '',
  password: '',
  // email: '',
  mobile: '',
  role: 'OPRT',
  status: 'ACTIVE',
};

export const useUserList = () => {
  const history = useHistory();
  const [userList, setUserList] = useState<Array<IUser>>([initialUser]);
  const [userSelected, setUserSelected] = useState<IUser>(initialUser);

  const { showToast } = useContext(ToasterActionsContext);
  const { invokeRequest } = useContext<IServiceActions>(ServiceActionsContext);
  const { user } = useContext<IAuthContext>(AuthContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);

  const [isEdit, setisEdit] = useState(false);

  const getUserList = async () => {
    try {
      invokeRequest({
        methodDescriptor: AuthApi.ListUser,
        request: new UserAuthResDtoList(),
        onStart: () => {},
        onEnd: (code: grpc.Code) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setUserList(data.listList);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const openUserDetail = (username: string) => {
    setisEdit(true);
    if (userSelected.username === username) {
      handleShow();
    } else {
      const request = new UserAuthResDto();
      request.setUsername(username);
      try {
        invokeRequest({
          methodDescriptor: AuthApi.UserProfile,
          request: request,
          onStart: () => {},
          onEnd: (code: grpc.Code) => {
            if (code === grpc.Code.Unknown) {
              showToast({
                message: 'network error connection to server',
                toastLegend: 'ERROR',
              });
            }
          },
          onMessage: (message) => {
            const data: any = message.toObject();
            if (data) {
              setUserSelected(data);
              handleShow();
            }
          },
        });
      } catch (error) {
        console.log(`Error ${error}`);
      }
    }
  };

  const createNewUser = async (data: IUser) => {
    try {
      const mobileCatches = data.mobile.replace(/^0+/, '62');
      const request = new UserAuthDto();
      request.setUsername(data.username);
      request.setPassword(data.password ?? '');
      request.setMobile(mobileCatches);
      request.setRole(data.role);
      invokeRequest({
        methodDescriptor: AuthApi.Register,
        request: request,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          console.log(code, message);
          if (code === grpc.Code.AlreadyExists) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Aborted) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Berhasil',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => {
              history.go(0);
            }, 1600);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const editUser = async (data: IUser) => {
    try {
      const mobileCatches = data.mobile.replace(/^0+/, '62');
      const request = new UserAuthResDto();
      request.setUsername(data.username);
      request.setMobile(mobileCatches);
      request.setRole(data.role);
      request.setStatus(data.status);
      invokeRequest({
        methodDescriptor: AuthApi.EditUser,
        request: request,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          if (code === grpc.Code.AlreadyExists) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Aborted) {
            showToast({
              message: message,
              toastLegend: 'ERROR',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Berhasil',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => {
              history.go(0);
            }, 1600);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const deleteSelectedUser = (username: string) => {
    try {
      const request = new google_protobuf_wrappers_pb.StringValue();
      request.setValue(username);
      invokeRequest({
        methodDescriptor: AuthApi.DeleteUserByUsername,
        request: request,
        onStart: () => {},
        onEnd: (code: grpc.Code, message) => {
          console.log(code, message);
          if (code === grpc.Code.PermissionDenied) {
            showToast({
              message: message,
              toastLegend: 'WARNING',
            });
          }
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            showToast({
              message: 'Berhasil',
              toastLegend: 'SUCCESS',
            });
            setTimeout(() => {
              history.go(0)
            }, 1600);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const deleteUser = async (username: string) => {
    MySwal.fire({
      title: 'Apakah Anda yakin?',
      text: `Menghapus ${username}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSelectedUser(username);
      }
    });
  };

  const addNewUserModal = () => {
    setisEdit(false);
    setUserSelected(initialUser);
    handleShow();
  };

  const setupAsyncData = async () => {
    await getUserList();
  };

  const useMount = () => {
    setupAsyncData();
  };

  useEffect(useMount, []);

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    user.role === 'OPRT' && setRedirect(true);
  }, []);

  const useUserListValue = {
    userList,
    userSelected,
    isOpen,
    isEdit,
    redirect,
    handleClose,
    openUserDetail,
    addNewUserModal,
    createNewUser,
    editUser,
    deleteUser,
  };

  return useUserListValue;
};
