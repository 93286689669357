import React from 'react';

class ErrorBoundary extends React.Component<{}, any> {
  constructor(props:any) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error:any) {
    this.setState({
      error: error,
    });
  }

  render() {
    if (!!this.state.error) {
      // window.location.reload();
      console.log(this.state);
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
