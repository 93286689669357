import React, { FC, ILoginState } from 'react';
import { Button } from 'react-bootstrap';
import Toast from '../../components/Toast/Toast';
import { useLogin } from './useLogin';

const Login: FC = () => {

window.onload = () => {
  sessionStorage.clear();
}

  const {
    username,
    password,
    isDisabled,
    handleChange,
    submitLogin
  } : ILoginState = useLogin();

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <b>Pondok</b>Rasamala
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Resort Bernuansa Alam Pedesaan</p>
            <form onSubmit={submitLogin({ username, password })}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  value={username}
                  onChange={handleChange('username')}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={handleChange('password')}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'} onClick={() => {setShowPassword(!showPassword)}}/>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                </div> */}
                <div className="col-12 mb-1">
                  <Button
                    type="submit"
                    variant="success"
                    disabled={isDisabled}
                    block
                  >
                    Sign In
                  </Button>
                </div>
                {/* <div className="col-12 mb-0">
                  <Button variant="primary" block onClick={notify}>
                    Sign In
                  </Button>
                </div> */}
              </div>
            </form>
            {/* <p className="mb-1">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p className="mb-0">
              <a href="register.html" className="text-center">
                Register a new membership
              </a>
            </p> */}
          </div>
        </div>
      </div>
      <Toast />
    </div>
  );
};

export default Login;
