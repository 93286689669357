import moment from 'moment';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import BoxInfo from '../../components/BoxInfo/BoxInfo';
import AppModal from '../../components/Modal/AppModal';
import { useHome } from './useHome';

export interface IRoomDetail {
  price?: number;
  roomName: string;
  roomType?: string;
  costumerName: string;
  companyName: string;
  orderNumber: string;
  orderStatus: string;
}

const ModalContentItem: React.FC<IRoomDetail> = ({
  price,
  roomName,
  costumerName,
  companyName,
  orderNumber,
  orderStatus,
}) => {
  return (
    <div className="list-group-item list-group-item-action flex-column align-items-start">
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{roomName}</h5>
        <small>{orderStatus ?? ''}</small>
      </div>
      <p className="mb-1">{companyName ?? costumerName}</p>
      <small>{price ? `Rp. ${price}` : orderNumber}</small>
    </div>
  );
};

const Home = () => {
  const {
    dataHome,
    user,
    isOpen,
    dataRoomDetail,
    handleClose,
    getAvailData,
    getCheckInData,
    getBookingData,
    getCheckoutData,
    getBlmLunasData,
  } = useHome();

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Halo, {user?.username}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">
                  {moment().format('dddd, DD MMMM YYYY').toString()}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <BoxInfo
                count={dataHome.avail}
                title="Available"
                type="info"
                navigateTo={getAvailData}
              />
            </div>
            <div className="col-lg-3 col-6">
              <BoxInfo
                count={dataHome.book}
                title="Booking"
                type="warning"
                navigateTo={getBookingData}
                customStyle={{ color: 'white' }}
              />
            </div>
            <div className="col-lg-3 col-6">
              <BoxInfo
                count={dataHome.cin}
                title="Check In"
                type="success"
                navigateTo={getCheckInData}
              />
            </div>
            <div className="col-lg-3 col-6">
              <BoxInfo
                count={dataHome.cout}
                title="Check Out"
                type="danger"
                navigateTo={getCheckoutData}
              />
            </div>
            <div className="col-lg-12 col-6">
              <BoxInfo
                count={dataHome.blmlunas}
                title="Belum Lunas"
                type="purple"
                navigateTo={getBlmLunasData}
              />
            </div>
          </div>
          {user.role === 'OWNR' || user.role === 'SADM' && (
          <div className="row">
            <div className="col-12">
              <Card className="text-center">
                <Card.Header className="text-bold">
                  Pendapatan gross sewa hari ini
                </Card.Header>
                <Card.Body>
                  <Button
                    className="text-bold"
                    disabled
                    as="div"
                    variant="warning"
                    size="lg"
                    block
                  >
                    Rp. {dataHome.gross.toLocaleString()}
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
          )}
        </div>
      </section>
      <AppModal
        title={dataRoomDetail.title}
        isOpen={isOpen}
        closeAction={handleClose}
      >
        <div className="list-group">
          {dataRoomDetail.data.map((x: any, index: number) => (
            <ModalContentItem
              key={index}
              roomName={x.roomname ?? ''}
              costumerName={x.costumername ?? ''}
              price={x.price ?? null}
              companyName={x.companyname ?? ''}
              orderNumber={x.ordernumber ?? ''}
              orderStatus={x.orderstatus ?? ''}
            />
          ))}
        </div>
      </AppModal>
    </>
  );
};

export default Home;
