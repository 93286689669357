import { Formik } from 'formik';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useChangePassword } from './useChangePassword';

const ChangePassword = () => {
  const { formInitialState, formSchema, updatePassword } = useChangePassword();
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <React.Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Change Password</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/profile">Profile</Link>
                </li>
                <li className="breadcrumb-item active">Change Password</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <Card>
            <Card.Body>
              <Row className="my-2">
                <Col md="12">
                  <Formik
                    enableReinitialize
                    initialValues={formInitialState}
                    validationSchema={formSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);
                      await updatePassword(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        {/* <Form.Group as={Row} controlId="oldpassCtrl">
                          <Form.Label column sm={2}>
                            Password Lama
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="password"
                              autoComplete="off"
                              name="oldpass"
                              placeholder="Password Lama"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.oldpass}
                              isInvalid={!!errors.oldpass && !!touched.oldpass}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.oldpass}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group> */}
                        <Form.Group as={Row} controlId="newpassCtrl">
                          <Form.Label column sm={2}>
                            Password Baru
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              autoComplete="off"
                              type={showPassword ? 'text' : 'password'}
                              name="newpass"
                              placeholder="Password Baru"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.newpass}
                              isInvalid={!!errors.newpass && !!touched.newpass}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.newpass}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="confpassCtrl">
                          <Form.Label column sm={2}>
                            Konfirmasi Password
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type={showPassword ? 'text' : 'password'}
                              autoComplete="off"
                              name="confpass"
                              placeholder="Konfirmasi Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confpass}
                              isInvalid={
                                !!errors.confpass && !!touched.confpass
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.confpass}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Button
                          variant="success"
                          type="submit"
                          className="float-right mr-2"
                          disabled={isSubmitting && !errors}
                        >
                          Simpan
                        </Button>
                        <Button
                          variant="info"
                          className="float-right mr-2"
                          onClick={() => {setShowPassword(!showPassword)}}
                        >
                          {showPassword ? "Sembunyikan" : "Tampilkan"}
                        </Button>
                      </form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ChangePassword;
