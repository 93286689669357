import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import AppModal from '../../components/Modal/AppModal';
import DetailUser from './DetailUser/DetailUser';
import { useUserList } from './useUserList';

const User = () => {
  const {
    userList,
    userSelected,
    isOpen,
    handleClose,
    openUserDetail,
    addNewUserModal,
    editUser,
    deleteUser,
    isEdit,
    redirect
  } = useUserList();

  function redirectTo() {
    return <Redirect to="/" />;
  }

  if (redirect) return redirectTo();
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>User List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">User List</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Card
                className="text-center"
                style={{ height: '120px' }}
                onClick={addNewUserModal}
              >
                <Card.Body>
                  <i style={{ fontSize: '30px' }} className="fas fa-plus" />
                </Card.Body>
                <Card.Footer>
                  <span className="text-center text-bold">Tambah User</span>
                </Card.Footer>
              </Card>
            </div>
            {userList.map((x, index: number) => (
              <div className="col-lg-2" key={index}>
                <Card
                  style={{ height: '120px' }}
                  className="text-center"
                >
                  <Card.Body className="text-bold" onClick={() => openUserDetail(x.username)}>
                    {/* <div className="d-flex justify-content-end pb-2">
                      <i
                        onClick={() => console.log('click')}
                        className="fas fa-trash"
                        style={{ color: '#dc3545' }}
                      />
                    </div> */}
                    {x.username}
                  </Card.Body>
                  <Card.Footer>
                    <div className="row">
                      <div className="col-4">
                        <Card.Link href="#">
                          <i className="fab fa-whatsapp text-success"></i>
                        </Card.Link>
                      </div>
                      <div className="col-4">
                        <Card.Link href="#">
                          <i className="fas fa-phone-alt"></i>
                        </Card.Link>
                      </div>
                      <div className="col-4" onClick={() => deleteUser(x.username)}>
                        <Card.Link>
                          <i className="fas fa-trash text-danger" />
                        </Card.Link>
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <AppModal
        title={userSelected.username ? 'Detail User' : 'Create new user'}
        isOpen={isOpen}
        closeAction={handleClose}
        hasCustomButton={true}
      >
        <DetailUser
          {...userSelected}
          closeAction={handleClose}
          isEdit={isEdit}
        />
      </AppModal>
    </>
  );
};

export default User;
