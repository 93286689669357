import React from "react";
import {
  Document,
  Page,
  StyleSheet,
  Image,
  Text,
  Font,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader
} from '@david.kucsai/react-pdf-table';
import rasamalaLogo from '../../assets/rasamala/rasamala-sidebar.png';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    padding: "0 40px"
  },
  logo: {
    height: "100px",
    width: "100px"
  },
  title: {
    fontSize: 32,
    textAlign: 'center',
    position: "absolute",
    top: 32,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  address: {
    fontFamily: 'Times-Roman',
    fontSize: 14,
    textAlign: 'justify',
    margin: 12,
    width: "300px"
  },
  periode: {
    fontFamily: 'Times-Roman',
    fontSize: 12,
    margin: 12,
    position: 'absolute',
    top: 99,
    right: 40,
    textAlign: 'left',
  },
  grossInc: {
    fontFamily: 'Times-Roman',
    fontSize: 18,
    margin: 12,
    textAlign: 'center',
  },
  table: {
    padding: "15px 0 15px 0"
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

type IReportPDF = {
  data: any[],
  startDate: string,
  endDate: string,
  grossInc: any
};

const ReportPDF: React.FC<IReportPDF> = (props) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>
        <Image
          src={rasamalaLogo}
          style={styles.logo}
        />
        <Text style={styles.title}>Pemasukan Kotor</Text>
        <Text style={styles.address}>
          Pondok Rasamala {"\n"}
          Kawasan Wisata {"\n"}
          Jl. Raya Gn. Salak Endah Jl.  Gn. Bunder {"\n"}
          Gn. Sari, Kec. Pamijahan, Bogor, Jawa Barat 16810 {"\n"}
          Indonesia
        </Text>
        <Text style={styles.periode}>
          Periode:  {props.startDate} s/d {props.endDate}
        </Text>

        {/* PageNumber */}
        {/* <Text style={styles.periode} render={({ pageNumber, totalPages }) => (
          `
            Halaman: ${pageNumber} / ${totalPages}
          `
        )} fixed /> */}

        <Table
          data={props.data.map((r) => (
            {
              tanggal: r.tanggal,
              noPesanan: r.noPesanan,
              namaPelanggan: r.namaPelanggan,
              penginapan: r.penginapan.toString(),
              checkIn: r.checkIn,
              checkOut: r.checkOut,
              hargaBayar: r.hargaBayar,
              pemasukan: r.pemasukan
            }
          ))}
        >
          <TableHeader textAlign={"center"}>
            <TableCell weighting={0.4}>Tanggal</TableCell>
            <TableCell weighting={0.7}>No Pesanan</TableCell>
            <TableCell weighting={0.7}>Nama Pelanggan</TableCell>
            <TableCell weighting={1}>Penginapan</TableCell>
            <TableCell weighting={0.5}>Check-In</TableCell>
            <TableCell weighting={0.5}>Check-Out</TableCell>
            <TableCell weighting={0.5}>Harga Bayar</TableCell>
            <TableCell weighting={0.5}>Pemasukan</TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell weighting={0.4} getContent={(r) => r.tanggal} />
            <DataTableCell weighting={0.7} getContent={(r) => r.noPesanan} />
            <DataTableCell weighting={0.7} getContent={(r) => r.namaPelanggan} />
            <DataTableCell weighting={1} getContent={(r) => r.penginapan} />
            <DataTableCell weighting={0.5} getContent={(r) => r.checkIn} />
            <DataTableCell weighting={0.5} getContent={(r) => r.checkOut} />
            <DataTableCell weighting={0.5} getContent={(r) => r.hargaBayar} />
            <DataTableCell weighting={0.5} getContent={(r) => r.pemasukan} />
          </TableBody>
        </Table>
        <Text style={styles.grossInc}>
          Pemasukan Akhir:  Rp. {props.grossInc}
        </Text>
      </Page>
    </Document>
  );
};

export default ReportPDF;