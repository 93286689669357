import React from 'react';
import { useHistory } from "react-router-dom";
import { grpc } from '@improbable-eng/grpc-web';
import {
  IServiceActions,
  ServiceActionsContext,
} from '../../contexts/ServiceContext';
import { ToasterActionsContext } from '../../contexts/ToasterContext';
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import { GenerateReportDto, GenerateReportReqDto, GenerateReportResDto } from '../../protofile/report/report_pb';
import { ReportApi } from '../../protofile/report/report_pb_service';

const useReport = () => {
  const history = useHistory();
  const { showToast } = React.useContext(ToasterActionsContext);
  const { invokeRequest } = React.useContext<IServiceActions>(ServiceActionsContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isRightDateRange, setIsRightDateRange] = React.useState<any>("");

  const [reportList, setReportList] = React.useState<Array<GenerateReportDto.AsObject>>(
    []
  );
  const [reportDto, setReportDto] = React.useState<GenerateReportResDto.AsObject>();

  const sessionStartDate = sessionStorage.getItem("reportStartDate") !== null ? sessionStorage.getItem("reportStartDate") : new Date();
  let [startDate, setStartDate] = React.useState<Date | any>(sessionStartDate);
  sessionStorage.setItem("reportStartDate", startDate);

  const sessionEndDate = sessionStorage.getItem("reportEndDate") !== null ? sessionStorage.getItem("reportEndDate") : new Date();
  let [endDate, setEndDate] = React.useState<Date | any>(sessionEndDate);
  sessionStorage.setItem("reportEndDate", endDate);

  const sessionMonthly = sessionStorage.getItem("reportMonthly") !== null ? sessionStorage.getItem("reportMonthly") : new Date();
  const [monthly, setMonthly] = React.useState<Date | any>(sessionMonthly);
  sessionStorage.setItem("reportMonthly", monthly);
  const m = new Date(monthly).getMonth(), y = new Date(monthly).getFullYear();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);
  const handleMonthly = () => {
    setStartDate(firstDay);
    setEndDate(lastDay);
  };
  React.useEffect(handleMonthly, [monthly]);

  const handleShow = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const formatCreateDate = (seconds: any) => {
    let date = new Date(0); // Epoch
    date.setSeconds(seconds + 25200);
    let stringCrateDate =
      ("0" + date.getDate()).slice(-2) + "/" +
      ("0" + (date.getMonth() + 1)).slice(-2);
    return stringCrateDate;
  };

  const formatBookingDate = (seconds: any) => {
    let date = new Date(0); // Epoch
    date.setSeconds(seconds + 25200);
    let stringBookingDate =
      ("0" + date.getDate()).slice(-2) + "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear();
    return stringBookingDate;
  };

  const monthNames = (seconds: any) => {
    let date = new Date(0); // Epoch
    date.setSeconds(seconds + 25200);
    const names = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
      "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    let stringMonth = names[date.getMonth()];
    return stringMonth;
  };

  const getReportList = async () => {
    try {

      const getStartDate = new google_protobuf_timestamp_pb.Timestamp();
      getStartDate.setSeconds(Math.round(new Date(startDate).getTime() / 1000));
      getStartDate.setNanos(0);
      const getEndDate = new google_protobuf_timestamp_pb.Timestamp();
      getEndDate.setSeconds(Math.round(new Date(endDate).getTime() / 1000));
      getEndDate.setNanos(0);

      const req = new GenerateReportReqDto();
      req.setStartdate(getStartDate)
      req.setEnddate(getEndDate);

      invokeRequest({
        methodDescriptor: ReportApi.GenerateReport,
        request: req,
        onStart: () => { },
        onEnd: (
          code: grpc.Code,
          message: string | undefined,
          trailers: grpc.Metadata,
        ) => {
          if (code === grpc.Code.Unknown) {
            showToast({
              message: 'network error connection to server',
              toastLegend: 'ERROR',
            });
          }
        },
        onMessage: (message) => {
          const data: any = message.toObject();
          if (data) {
            setReportList(data.listreportList);
            setReportDto(data);
          }
        },
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  const setupAsyncData = async () => {
    await getReportList();
  };

  const useMount = () => {
    setupAsyncData();
  };

  React.useEffect(useMount, [startDate, endDate]);

  return {
    startDate,
    endDate,
    monthly,
    isOpen,
    isDisabled,
    isRightDateRange,
    reportList,
    reportDto,
    setIsOpen,
    setIsDisabled,
    setIsRightDateRange,
    setStartDate,
    setEndDate,
    setMonthly,
    handleShow,
    handleClose,
    formatCreateDate,
    formatBookingDate,
    monthNames,
  };
};

export default useReport;