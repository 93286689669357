// source: home.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.protoapi.DtlAvailResDto', null, global);
goog.exportSymbol('proto.protoapi.DtlAvailResDtoList', null, global);
goog.exportSymbol('proto.protoapi.DtlBlmLunasResDto', null, global);
goog.exportSymbol('proto.protoapi.DtlBlmLunasResDtoList', null, global);
goog.exportSymbol('proto.protoapi.DtlBookedResDto', null, global);
goog.exportSymbol('proto.protoapi.DtlBookedResDtoList', null, global);
goog.exportSymbol('proto.protoapi.DtlCheckinResDto', null, global);
goog.exportSymbol('proto.protoapi.DtlCheckinResDtoList', null, global);
goog.exportSymbol('proto.protoapi.DtlCheckoutResDto', null, global);
goog.exportSymbol('proto.protoapi.DtlCheckoutResDtoList', null, global);
goog.exportSymbol('proto.protoapi.HomeDto', null, global);
goog.exportSymbol('proto.protoapi.UserFirebaseDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.HomeDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.HomeDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.HomeDto.displayName = 'proto.protoapi.HomeDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlAvailResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.DtlAvailResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlAvailResDto.displayName = 'proto.protoapi.DtlAvailResDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlAvailResDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.DtlAvailResDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.DtlAvailResDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlAvailResDtoList.displayName = 'proto.protoapi.DtlAvailResDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlBookedResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.DtlBookedResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlBookedResDto.displayName = 'proto.protoapi.DtlBookedResDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlBookedResDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.DtlBookedResDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.DtlBookedResDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlBookedResDtoList.displayName = 'proto.protoapi.DtlBookedResDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlCheckinResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.DtlCheckinResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlCheckinResDto.displayName = 'proto.protoapi.DtlCheckinResDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlCheckinResDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.DtlCheckinResDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.DtlCheckinResDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlCheckinResDtoList.displayName = 'proto.protoapi.DtlCheckinResDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlCheckoutResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.DtlCheckoutResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlCheckoutResDto.displayName = 'proto.protoapi.DtlCheckoutResDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlCheckoutResDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.DtlCheckoutResDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.DtlCheckoutResDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlCheckoutResDtoList.displayName = 'proto.protoapi.DtlCheckoutResDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlBlmLunasResDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.DtlBlmLunasResDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlBlmLunasResDto.displayName = 'proto.protoapi.DtlBlmLunasResDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.DtlBlmLunasResDtoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protoapi.DtlBlmLunasResDtoList.repeatedFields_, null);
};
goog.inherits(proto.protoapi.DtlBlmLunasResDtoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.DtlBlmLunasResDtoList.displayName = 'proto.protoapi.DtlBlmLunasResDtoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protoapi.UserFirebaseDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protoapi.UserFirebaseDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protoapi.UserFirebaseDto.displayName = 'proto.protoapi.UserFirebaseDto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.HomeDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.HomeDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.HomeDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.HomeDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    avail: jspb.Message.getFieldWithDefault(msg, 1, 0),
    book: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cout: jspb.Message.getFieldWithDefault(msg, 4, 0),
    blmlunas: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gross: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalnett: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totaldisc: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalordadd: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.HomeDto}
 */
proto.protoapi.HomeDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.HomeDto;
  return proto.protoapi.HomeDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.HomeDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.HomeDto}
 */
proto.protoapi.HomeDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvail(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBook(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCout(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlmlunas(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGross(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalnett(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotaldisc(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalordadd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.HomeDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.HomeDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.HomeDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.HomeDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvail();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBook();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCin();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCout();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBlmlunas();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getGross();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalnett();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTotaldisc();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTotalordadd();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional int32 avail = 1;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getAvail = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setAvail = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 book = 2;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getBook = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setBook = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 cin = 3;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getCin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setCin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 cout = 4;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getCout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setCout = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 blmLunas = 5;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getBlmlunas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setBlmlunas = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 gross = 6;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getGross = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setGross = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 totalNett = 7;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getTotalnett = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setTotalnett = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 totalDisc = 8;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getTotaldisc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setTotaldisc = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 totalOrdAdd = 9;
 * @return {number}
 */
proto.protoapi.HomeDto.prototype.getTotalordadd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.HomeDto} returns this
 */
proto.protoapi.HomeDto.prototype.setTotalordadd = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlAvailResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlAvailResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlAvailResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlAvailResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlAvailResDto}
 */
proto.protoapi.DtlAvailResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlAvailResDto;
  return proto.protoapi.DtlAvailResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlAvailResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlAvailResDto}
 */
proto.protoapi.DtlAvailResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlAvailResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlAvailResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlAvailResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlAvailResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderstatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string roomName = 1;
 * @return {string}
 */
proto.protoapi.DtlAvailResDto.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlAvailResDto} returns this
 */
proto.protoapi.DtlAvailResDto.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 price = 2;
 * @return {number}
 */
proto.protoapi.DtlAvailResDto.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protoapi.DtlAvailResDto} returns this
 */
proto.protoapi.DtlAvailResDto.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.protoapi.DtlAvailResDto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlAvailResDto} returns this
 */
proto.protoapi.DtlAvailResDto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string orderStatus = 4;
 * @return {string}
 */
proto.protoapi.DtlAvailResDto.prototype.getOrderstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlAvailResDto} returns this
 */
proto.protoapi.DtlAvailResDto.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.DtlAvailResDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlAvailResDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlAvailResDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlAvailResDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlAvailResDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.DtlAvailResDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlAvailResDtoList}
 */
proto.protoapi.DtlAvailResDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlAvailResDtoList;
  return proto.protoapi.DtlAvailResDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlAvailResDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlAvailResDtoList}
 */
proto.protoapi.DtlAvailResDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.DtlAvailResDto;
      reader.readMessage(value,proto.protoapi.DtlAvailResDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlAvailResDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlAvailResDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlAvailResDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlAvailResDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.DtlAvailResDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DtlAvailResDto list = 1;
 * @return {!Array<!proto.protoapi.DtlAvailResDto>}
 */
proto.protoapi.DtlAvailResDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.DtlAvailResDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.DtlAvailResDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.DtlAvailResDto>} value
 * @return {!proto.protoapi.DtlAvailResDtoList} returns this
*/
proto.protoapi.DtlAvailResDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.DtlAvailResDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.DtlAvailResDto}
 */
proto.protoapi.DtlAvailResDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.DtlAvailResDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.DtlAvailResDtoList} returns this
 */
proto.protoapi.DtlAvailResDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlBookedResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlBookedResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlBookedResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBookedResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomtype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    costumername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlBookedResDto}
 */
proto.protoapi.DtlBookedResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlBookedResDto;
  return proto.protoapi.DtlBookedResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlBookedResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlBookedResDto}
 */
proto.protoapi.DtlBookedResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostumername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlBookedResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlBookedResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlBookedResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBookedResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomtype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCostumername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderstatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string roomName = 1;
 * @return {string}
 */
proto.protoapi.DtlBookedResDto.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBookedResDto} returns this
 */
proto.protoapi.DtlBookedResDto.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomType = 2;
 * @return {string}
 */
proto.protoapi.DtlBookedResDto.prototype.getRoomtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBookedResDto} returns this
 */
proto.protoapi.DtlBookedResDto.prototype.setRoomtype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string costumerName = 3;
 * @return {string}
 */
proto.protoapi.DtlBookedResDto.prototype.getCostumername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBookedResDto} returns this
 */
proto.protoapi.DtlBookedResDto.prototype.setCostumername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string companyName = 4;
 * @return {string}
 */
proto.protoapi.DtlBookedResDto.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBookedResDto} returns this
 */
proto.protoapi.DtlBookedResDto.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orderNumber = 5;
 * @return {string}
 */
proto.protoapi.DtlBookedResDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBookedResDto} returns this
 */
proto.protoapi.DtlBookedResDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string orderStatus = 6;
 * @return {string}
 */
proto.protoapi.DtlBookedResDto.prototype.getOrderstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBookedResDto} returns this
 */
proto.protoapi.DtlBookedResDto.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.DtlBookedResDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlBookedResDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlBookedResDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlBookedResDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBookedResDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.DtlBookedResDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlBookedResDtoList}
 */
proto.protoapi.DtlBookedResDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlBookedResDtoList;
  return proto.protoapi.DtlBookedResDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlBookedResDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlBookedResDtoList}
 */
proto.protoapi.DtlBookedResDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.DtlBookedResDto;
      reader.readMessage(value,proto.protoapi.DtlBookedResDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlBookedResDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlBookedResDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlBookedResDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBookedResDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.DtlBookedResDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DtlBookedResDto list = 1;
 * @return {!Array<!proto.protoapi.DtlBookedResDto>}
 */
proto.protoapi.DtlBookedResDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.DtlBookedResDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.DtlBookedResDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.DtlBookedResDto>} value
 * @return {!proto.protoapi.DtlBookedResDtoList} returns this
*/
proto.protoapi.DtlBookedResDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.DtlBookedResDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.DtlBookedResDto}
 */
proto.protoapi.DtlBookedResDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.DtlBookedResDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.DtlBookedResDtoList} returns this
 */
proto.protoapi.DtlBookedResDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlCheckinResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlCheckinResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlCheckinResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckinResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomtype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    costumername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlCheckinResDto}
 */
proto.protoapi.DtlCheckinResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlCheckinResDto;
  return proto.protoapi.DtlCheckinResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlCheckinResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlCheckinResDto}
 */
proto.protoapi.DtlCheckinResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostumername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlCheckinResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlCheckinResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlCheckinResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckinResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomtype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCostumername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderstatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string roomName = 1;
 * @return {string}
 */
proto.protoapi.DtlCheckinResDto.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckinResDto} returns this
 */
proto.protoapi.DtlCheckinResDto.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomType = 2;
 * @return {string}
 */
proto.protoapi.DtlCheckinResDto.prototype.getRoomtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckinResDto} returns this
 */
proto.protoapi.DtlCheckinResDto.prototype.setRoomtype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string costumerName = 3;
 * @return {string}
 */
proto.protoapi.DtlCheckinResDto.prototype.getCostumername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckinResDto} returns this
 */
proto.protoapi.DtlCheckinResDto.prototype.setCostumername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string companyName = 4;
 * @return {string}
 */
proto.protoapi.DtlCheckinResDto.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckinResDto} returns this
 */
proto.protoapi.DtlCheckinResDto.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orderNumber = 5;
 * @return {string}
 */
proto.protoapi.DtlCheckinResDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckinResDto} returns this
 */
proto.protoapi.DtlCheckinResDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string orderStatus = 6;
 * @return {string}
 */
proto.protoapi.DtlCheckinResDto.prototype.getOrderstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckinResDto} returns this
 */
proto.protoapi.DtlCheckinResDto.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.DtlCheckinResDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlCheckinResDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlCheckinResDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlCheckinResDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckinResDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.DtlCheckinResDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlCheckinResDtoList}
 */
proto.protoapi.DtlCheckinResDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlCheckinResDtoList;
  return proto.protoapi.DtlCheckinResDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlCheckinResDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlCheckinResDtoList}
 */
proto.protoapi.DtlCheckinResDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.DtlCheckinResDto;
      reader.readMessage(value,proto.protoapi.DtlCheckinResDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlCheckinResDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlCheckinResDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlCheckinResDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckinResDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.DtlCheckinResDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DtlCheckinResDto list = 1;
 * @return {!Array<!proto.protoapi.DtlCheckinResDto>}
 */
proto.protoapi.DtlCheckinResDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.DtlCheckinResDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.DtlCheckinResDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.DtlCheckinResDto>} value
 * @return {!proto.protoapi.DtlCheckinResDtoList} returns this
*/
proto.protoapi.DtlCheckinResDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.DtlCheckinResDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.DtlCheckinResDto}
 */
proto.protoapi.DtlCheckinResDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.DtlCheckinResDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.DtlCheckinResDtoList} returns this
 */
proto.protoapi.DtlCheckinResDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlCheckoutResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlCheckoutResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlCheckoutResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckoutResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomtype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    costumername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlCheckoutResDto}
 */
proto.protoapi.DtlCheckoutResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlCheckoutResDto;
  return proto.protoapi.DtlCheckoutResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlCheckoutResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlCheckoutResDto}
 */
proto.protoapi.DtlCheckoutResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostumername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlCheckoutResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlCheckoutResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlCheckoutResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckoutResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomtype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCostumername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderstatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string roomName = 1;
 * @return {string}
 */
proto.protoapi.DtlCheckoutResDto.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckoutResDto} returns this
 */
proto.protoapi.DtlCheckoutResDto.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomType = 2;
 * @return {string}
 */
proto.protoapi.DtlCheckoutResDto.prototype.getRoomtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckoutResDto} returns this
 */
proto.protoapi.DtlCheckoutResDto.prototype.setRoomtype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string costumerName = 3;
 * @return {string}
 */
proto.protoapi.DtlCheckoutResDto.prototype.getCostumername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckoutResDto} returns this
 */
proto.protoapi.DtlCheckoutResDto.prototype.setCostumername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string companyName = 4;
 * @return {string}
 */
proto.protoapi.DtlCheckoutResDto.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckoutResDto} returns this
 */
proto.protoapi.DtlCheckoutResDto.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orderNumber = 5;
 * @return {string}
 */
proto.protoapi.DtlCheckoutResDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckoutResDto} returns this
 */
proto.protoapi.DtlCheckoutResDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string orderStatus = 6;
 * @return {string}
 */
proto.protoapi.DtlCheckoutResDto.prototype.getOrderstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlCheckoutResDto} returns this
 */
proto.protoapi.DtlCheckoutResDto.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.DtlCheckoutResDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlCheckoutResDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlCheckoutResDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlCheckoutResDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckoutResDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.DtlCheckoutResDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlCheckoutResDtoList}
 */
proto.protoapi.DtlCheckoutResDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlCheckoutResDtoList;
  return proto.protoapi.DtlCheckoutResDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlCheckoutResDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlCheckoutResDtoList}
 */
proto.protoapi.DtlCheckoutResDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.DtlCheckoutResDto;
      reader.readMessage(value,proto.protoapi.DtlCheckoutResDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlCheckoutResDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlCheckoutResDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlCheckoutResDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlCheckoutResDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.DtlCheckoutResDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DtlCheckoutResDto list = 1;
 * @return {!Array<!proto.protoapi.DtlCheckoutResDto>}
 */
proto.protoapi.DtlCheckoutResDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.DtlCheckoutResDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.DtlCheckoutResDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.DtlCheckoutResDto>} value
 * @return {!proto.protoapi.DtlCheckoutResDtoList} returns this
*/
proto.protoapi.DtlCheckoutResDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.DtlCheckoutResDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.DtlCheckoutResDto}
 */
proto.protoapi.DtlCheckoutResDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.DtlCheckoutResDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.DtlCheckoutResDtoList} returns this
 */
proto.protoapi.DtlCheckoutResDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlBlmLunasResDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlBlmLunasResDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBlmLunasResDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomtype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    costumername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlBlmLunasResDto}
 */
proto.protoapi.DtlBlmLunasResDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlBlmLunasResDto;
  return proto.protoapi.DtlBlmLunasResDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlBlmLunasResDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlBlmLunasResDto}
 */
proto.protoapi.DtlBlmLunasResDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostumername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlBlmLunasResDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlBlmLunasResDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBlmLunasResDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomtype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCostumername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderstatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string roomName = 1;
 * @return {string}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBlmLunasResDto} returns this
 */
proto.protoapi.DtlBlmLunasResDto.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomType = 2;
 * @return {string}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.getRoomtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBlmLunasResDto} returns this
 */
proto.protoapi.DtlBlmLunasResDto.prototype.setRoomtype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string costumerName = 3;
 * @return {string}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.getCostumername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBlmLunasResDto} returns this
 */
proto.protoapi.DtlBlmLunasResDto.prototype.setCostumername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string companyName = 4;
 * @return {string}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBlmLunasResDto} returns this
 */
proto.protoapi.DtlBlmLunasResDto.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orderNumber = 5;
 * @return {string}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBlmLunasResDto} returns this
 */
proto.protoapi.DtlBlmLunasResDto.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string orderStatus = 6;
 * @return {string}
 */
proto.protoapi.DtlBlmLunasResDto.prototype.getOrderstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.DtlBlmLunasResDto} returns this
 */
proto.protoapi.DtlBlmLunasResDto.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protoapi.DtlBlmLunasResDtoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.DtlBlmLunasResDtoList.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.DtlBlmLunasResDtoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.DtlBlmLunasResDtoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBlmLunasResDtoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.protoapi.DtlBlmLunasResDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.DtlBlmLunasResDtoList}
 */
proto.protoapi.DtlBlmLunasResDtoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.DtlBlmLunasResDtoList;
  return proto.protoapi.DtlBlmLunasResDtoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.DtlBlmLunasResDtoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.DtlBlmLunasResDtoList}
 */
proto.protoapi.DtlBlmLunasResDtoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protoapi.DtlBlmLunasResDto;
      reader.readMessage(value,proto.protoapi.DtlBlmLunasResDto.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.DtlBlmLunasResDtoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.DtlBlmLunasResDtoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.DtlBlmLunasResDtoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.DtlBlmLunasResDtoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protoapi.DtlBlmLunasResDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DtlBlmLunasResDto list = 1;
 * @return {!Array<!proto.protoapi.DtlBlmLunasResDto>}
 */
proto.protoapi.DtlBlmLunasResDtoList.prototype.getListList = function() {
  return /** @type{!Array<!proto.protoapi.DtlBlmLunasResDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protoapi.DtlBlmLunasResDto, 1));
};


/**
 * @param {!Array<!proto.protoapi.DtlBlmLunasResDto>} value
 * @return {!proto.protoapi.DtlBlmLunasResDtoList} returns this
*/
proto.protoapi.DtlBlmLunasResDtoList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protoapi.DtlBlmLunasResDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protoapi.DtlBlmLunasResDto}
 */
proto.protoapi.DtlBlmLunasResDtoList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protoapi.DtlBlmLunasResDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protoapi.DtlBlmLunasResDtoList} returns this
 */
proto.protoapi.DtlBlmLunasResDtoList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protoapi.UserFirebaseDto.prototype.toObject = function(opt_includeInstance) {
  return proto.protoapi.UserFirebaseDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protoapi.UserFirebaseDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.UserFirebaseDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceos: jspb.Message.getFieldWithDefault(msg, 2, ""),
    osversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    topic: jspb.Message.getFieldWithDefault(msg, 4, ""),
    registoken: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protoapi.UserFirebaseDto}
 */
proto.protoapi.UserFirebaseDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protoapi.UserFirebaseDto;
  return proto.protoapi.UserFirebaseDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protoapi.UserFirebaseDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protoapi.UserFirebaseDto}
 */
proto.protoapi.UserFirebaseDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceos(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsversion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protoapi.UserFirebaseDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protoapi.UserFirebaseDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protoapi.UserFirebaseDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protoapi.UserFirebaseDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceos();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOsversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegistoken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.protoapi.UserFirebaseDto.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.UserFirebaseDto} returns this
 */
proto.protoapi.UserFirebaseDto.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deviceOs = 2;
 * @return {string}
 */
proto.protoapi.UserFirebaseDto.prototype.getDeviceos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.UserFirebaseDto} returns this
 */
proto.protoapi.UserFirebaseDto.prototype.setDeviceos = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string osVersion = 3;
 * @return {string}
 */
proto.protoapi.UserFirebaseDto.prototype.getOsversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.UserFirebaseDto} returns this
 */
proto.protoapi.UserFirebaseDto.prototype.setOsversion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string topic = 4;
 * @return {string}
 */
proto.protoapi.UserFirebaseDto.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.UserFirebaseDto} returns this
 */
proto.protoapi.UserFirebaseDto.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string regisToken = 5;
 * @return {string}
 */
proto.protoapi.UserFirebaseDto.prototype.getRegistoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protoapi.UserFirebaseDto} returns this
 */
proto.protoapi.UserFirebaseDto.prototype.setRegistoken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.protoapi);
