import React, { FC, useReducer } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

export type ToastLegend =
  | 'INFO'
  | 'SUCCESS'
  | 'WARNING'
  | 'ERROR'
  | 'DEFAULT'
  | 'DARK';

export type ToastAction = 'OPEN_TOAST' | 'CLOSE_TOAST';

export interface IToastState {
  isToastShow?: boolean;
  message: string;
  toastLegend: ToastLegend;
}

const initialStateToast: IToastState = {
  isToastShow: false,
  message: '',
  toastLegend: 'DEFAULT',
};

export interface IToastActions {
  showToast: ({ message, toastLegend }: IToastState) => void;
  closeToast: () => void;
}

type Action = { type: ToastAction; payload: IToastState };

const reducer = (state: IToastState, action: Action): IToastState => {
  switch (action.type) {
    case 'OPEN_TOAST':
      return {
        ...state,
        isToastShow: true,
        message: action.payload.message,
        toastLegend: action.payload.toastLegend,
      };

    case 'CLOSE_TOAST':
      return {
        ...state,
        isToastShow: false,
      };

    default:
      throw new Error('Unhandled toast reducer actions');
  }
};

const setToastLegend = ({ message, toastLegend }: IToastState) => {
  switch (toastLegend) {
    case 'INFO':
      return toast.info(message);
    case 'DARK':
      return toast.dark(message);
    case 'ERROR':
      return toast.error(message);
    case 'SUCCESS':
      return toast.success(message);
    case 'WARNING':
      return toast.warn(message);
    default:
      return toast(message);
  }
};

export const ToasterContext = React.createContext<IToastState>(
  initialStateToast,
);
export const ToasterActionsContext = React.createContext<IToastActions>({
  showToast: () => {},
  closeToast: () => {},
});

export const ToasterProvider: FC = ({ children }) => {
  const [{ message, toastLegend }, dispatch] = useReducer(
    reducer,
    initialStateToast,
  );

  const showToast = ({ message, toastLegend }: IToastState) => {
    dispatch({
      type: 'OPEN_TOAST',
      payload: {
        isToastShow: true,
        message,
        toastLegend,
      },
    });
    setToastLegend({message, toastLegend })
  };

  const closeToast = () => {
    dispatch({ type: 'CLOSE_TOAST', payload: initialStateToast });
  };

  const toasterValue: IToastState = {
    // isToastShow,
    message,
    toastLegend,
  };
  const toasterActionsValue: IToastActions = {
    showToast,
    closeToast,
  };

  return (
    <ToasterContext.Provider value={toasterValue}>
      <ToasterActionsContext.Provider value={toasterActionsValue}>
        {children}
      </ToasterActionsContext.Provider>
    </ToasterContext.Provider>
  );
};

ToasterProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
