import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface IAppModalState {
  isOpen?: boolean;
  title: string;
  hasCustomButton?: boolean;
  closeAction?: VoidFunction;
  saveAction?: VoidFunction;
}

const AppModal: React.FC<IAppModalState> = ({
  children,
  hasCustomButton = false,
  isOpen,
  title,
  closeAction,
  saveAction,
}) => {
  let saveButton;

  if (!!saveAction) {
    saveButton = (
      <Button variant="primary" onClick={saveAction}>
        Save Changes
      </Button>
    );
  } else {
    saveButton = <div />;
  }

  return (
    <Modal animation={true} show={isOpen} onHide={closeAction} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      {!hasCustomButton ? (
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAction}>
            Close
          </Button>
          {saveButton}
        </Modal.Footer>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default AppModal;
