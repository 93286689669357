import { Formik } from 'formik';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useProfile } from './useProfile';

const Profile = () => {
  const {
    onEdit,
    dataUserProfile,
    formSchema,
    toggleEdit,
    updateSelfProfile,
  } = useProfile();
  return (
    <React.Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">{/* <h1>Profile</h1> */}</div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Profile</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <Card>
            <Card.Body>
              <Row
                style={{ borderBottom: '1px solid #e5e5e5' }}
                className="mb-4"
              >
                <Col md="12" className="d-flex">
                  <h4 className="pb-2">Your Profile</h4>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Formik
                    enableReinitialize
                    initialValues={dataUserProfile}
                    validationSchema={formSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);
                      await updateSelfProfile(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        <Form.Group as={Row} controlId="usernameCtrl">
                          <Form.Label column sm={2}>
                            Username
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="text"
                              name="username"
                              placeholder="Username"
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.username}
                              isInvalid={!!errors.username}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.username}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="emailCtrl">
                          <Form.Label column sm={2}>
                            Email
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="Email"
                              disabled={!onEdit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="mobileCtrl">
                          <Form.Label column sm={2}>
                            Phone Number
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="text"
                              name="mobile"
                              placeholder="Phone Number"
                              disabled={!onEdit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobile}
                              isInvalid={!!errors.mobile}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.mobile}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="roleCtrl">
                          <Form.Label column sm={2}>
                            Role
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="text"
                              name="role"
                              placeholder="Role"
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.role}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="statusCtrl">
                          <Form.Label column sm={2}>
                            Status
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Check
                              type="switch"
                              name="status"
                              id="status_switch"
                              label="Check this switch"
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                              checked={
                                values.status === 'ACTIVE' ? true : false
                              }
                            />
                          </Col>
                        </Form.Group>
                        {!onEdit ? (
                          <Button
                            variant="success"
                            className="float-right"
                            onClick={toggleEdit}
                          >
                            Ubah data profile
                          </Button>
                        ) : (
                          <div>
                            <Button
                              variant="default"
                              type="reset"
                              className="float-right mr-2"
                              onClick={toggleEdit}
                            >
                              Batal
                            </Button>
                            <Button
                              variant="success"
                              type="submit"
                              className="float-right mr-2"
                              disabled={isSubmitting && !errors}
                            >
                              Simpan
                            </Button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Profile;
