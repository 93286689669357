import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthActionsContext } from '../../../contexts/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button } from 'react-bootstrap';

export interface UserDropdownProps {
  username?: string;
}

const MySwal = withReactContent(Swal);

const UserDropdown: React.FC<UserDropdownProps> = ({ username }) => {
  const history = useHistory();
  const { logout } = useContext(AuthActionsContext);

  const openAlert = () => {
    MySwal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Keluar dari sesi',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogout();
      }
    });
  };

  const dropdownRef = useRef<any>(null);
  const [dropdownState, updateDropdownState] = useState({
    isDropdownOpen: false,
  });

  const toggleDropdown = () => {
    updateDropdownState({ isDropdownOpen: !dropdownState.isDropdownOpen });
  };

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      updateDropdownState({ isDropdownOpen: false });
    }
  };

  const handleLogout = () => {
    logout().then(() => history.go(0));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  });

  let className = 'dropdown-menu dropdown-menu-right p-0';

  if (dropdownState.isDropdownOpen) {
    className += ' show';
  }
  return (
    <li ref={dropdownRef} className="nav-item dropdown">
      <a
        onClick={toggleDropdown}
        type="button"
        className="nav-link"
        data-toggle="dropdown"
      >
        <i className="fas fa-users-cog mr-1" />
        <span className="text-bold">{username}</span>
      </a>
      <div className={className}>
        <Link to="/profile" className="dropdown-item">
          <i className="fas fa-users mr-2" />
          <span> Profile</span>
        </Link>
        <Link to="/profile/change-password" className="dropdown-item">
          <i className="fas fa-key mr-2" />
          <span> Change Password </span>
        </Link>
        <Button className="dropdown-item" onClick={openAlert}>
          <i className="fas fa-sign-out-alt mr-2" />
          <span> Logout</span>
        </Button>
      </div>
    </li>
  );
};

export default UserDropdown;
