import React, { useState } from 'react';
import { AccessTokenDto } from '../protofile/auth/auth_pb';
import Constants from '../utils/Constants';

export interface IAuthContext {
  user: AccessTokenDto.AsObject;
}
export interface IAuthActionsContext {
  login: ILoginContext;
  logout: ILogoutContext;
}

export interface ILoginContext {
  (data: any): void;
}

export interface ILogoutContext {
  (): Promise<void>;
}

let loginObject = localStorage.getItem(Constants.storageKey);
let parsedLoginObject: any;


if (loginObject) {
  parsedLoginObject = JSON.parse(loginObject);
}

const initialUserContext: AccessTokenDto.AsObject = {
  username: '',
  email: '',
  accesstoken: '',
  expiresin: 0,
  scope: '',
  tokentype: '',
  mobile: '',
  role: '',
};


export const AuthContext = React.createContext<IAuthContext>({
  user: initialUserContext,
});
export const AuthActionsContext = React.createContext<IAuthActionsContext>({
  login: () => {},
  logout: async () => {}
});

export const AuthProvider:React.FC = ({children}) => {
  const [user, setUser] = useState<AccessTokenDto.AsObject>(parsedLoginObject);

  const login:ILoginContext = (data) => {
    if(!!data) {
      localStorage.setItem(Constants.storageKey, JSON.stringify(data));
      setUser(data);
    }
  }
  const logout: ILogoutContext = () => {
    return new Promise<void>((resolve, reject) => {
      localStorage.removeItem(Constants.storageKey);
      setUser(initialUserContext);
      resolve();
    });
  }

  const authValue = {user};
  const authActionsValue = {login, logout};

  return(
    <AuthContext.Provider value={authValue}>
      <AuthActionsContext.Provider value={authActionsValue}>
        {children}
      </AuthActionsContext.Provider>
    </AuthContext.Provider>
  )
}


